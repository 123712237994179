import React from 'react';
import { string } from 'prop-types';
// import classNames from 'classnames';

import {
  H2,
  H4,
} from '../../../../components';

import css from './SectionReencleComposter.module.css';

const ReencleContentUsing = props => {
  const {
    sectionId,
  } = props;

  const imageArray = [
    {
      src: '/static/reencle/Using-icon_lawn.png',
      label: 'Lawn',
    },
    {
      src: '/static/reencle/Using-icon_garden.png',
      label: 'Garden',
    },
    {
      src: '/static/reencle/Using-icon_potted_plants.png',
      label: 'Potted Plants',
    },
    {
      src: '/static/reencle/Using-icon_seed_starting.png',
      label: 'Starting Seeds',
    },
    {
      src: '/static/reencle/Using-icon_tree_beds.png',
      label: 'Tree Beds',
    },
    {
      src: '/static/reencle/Using-icon_share_it.png',
      label: 'Share It',
    },
  ];

  return (
    <div style={{ textAlign: 'center' }}>
      <H2 className={css.tabSectionTitle}>Using Compost</H2>
      <p>Adding compost to your garden provides several benefits. After preparing your compost, use it to improve your soil and give your plants a steady supply of nutrients</p>
      <p className={css.highlightedText}>No garden? No problem!</p>
      <p>Compost can also be used to fertilize lawns, trees, bushes, flowers, and houseplants; or shared with a neighbor, friend, or organization who does garden.</p>
      <div id={sectionId} className={css.tabContentContainer} style={{ marginBottom: 60 }}>
        <div className={css.layout6}>
          {
            imageArray.map((img_data) => {
              return (
                <div className={css.layout6Item}>
                  <img src={img_data.src} className={css.responsiveImage} alt={img_data.label} />
                  <H4 className={css.layout6Label}>{img_data.label}</H4>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

ReencleContentUsing.defaultProps = {
  sectionId: null,
};

ReencleContentUsing.propTypes = {
  sectionId: string,
};

export default ReencleContentUsing;
