import intersection from 'lodash/intersection';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive, parseSelectFilterOptions } from '../../util/search';
import { createSlug, landingPagePath, parse, stringify } from '../../util/urlHelpers';
import { getStartOf, parseDateFromISO8601, subtractTime } from '../../util/dates';

/**
 * Create the name of the query parameter.
 *
 * @param {String} key Key extracted from listingExtendData config.
 * @param {String} scope Scope extracted from listingExtendData config.
 */
export const constructQueryParamName = (key, scope) => {
  const paramName = scope === 'meta' ? `meta_${key}` : `pub_${key}`;
  return paramName.replace(/\s/g, '_');
};

/**
 * Validates a filter search param against the default and extended data configuration of listings.
 *
 * All invalid param names and values are dropped
 *
 * @param {String} queryParamName Search parameter name
 * @param {Object} paramValue Search parameter value
 * @param {Object} listingFieldFilters extended data configuration with indexForSearch === true
 * @param {Object} defaultFilters configuration for default built-in filters.
 */
export const validURLParamForExtendedData = (
  queryParamName,
  paramValueRaw,
  listingFieldFilters,
  defaultFilters
) => {
  const paramValue = paramValueRaw.toString();

  // Price is built-in filter for listing entities
  if (queryParamName === 'price') {
    // Restrict price range to correct min & max
    const { min, max } = defaultFilters || {};
    const valueArray = paramValue ? paramValue.split(',') : [];
    const validValues = valueArray.map(v => {
      return v < min ? min : v > max ? max : v;
    });
    return validValues.length === 2 ? { [queryParamName]: validValues.join(',') } : {};
  } else if (queryParamName === 'keywords') {
    return paramValue.length > 0 ? { [queryParamName]: paramValue } : {};
  } else if (queryParamName === 'dates') {
    const searchTZ = 'Etc/UTC';
    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const dates = paramValue ? paramValue.split(',') : [];
    const hasValues = dates.length > 0;
    const startDate = hasValues ? parseDateFromISO8601(dates[0], searchTZ) : null;
    const endDate = hasValues ? parseDateFromISO8601(dates[1], searchTZ) : null;
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    return hasValidDates ? { [queryParamName]: paramValue } : {};
  }

  // Resolve configurations for extended data filters
  const listingFieldFilterConfig = listingFieldFilters.find(
    f => queryParamName === constructQueryParamName(f.key, f.scope)
  );

  if (listingFieldFilterConfig) {
    const { schemaType, enumOptions = [], filterConfig } = listingFieldFilterConfig;
    if ([SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM].includes(schemaType)) {
      const isSchemaTypeMultiEnum = schemaType === SCHEMA_TYPE_MULTI_ENUM;
      const searchMode = filterConfig?.searchMode;

      // Pick valid select options only
      const valueArray = parseSelectFilterOptions(paramValue);
      const allowedValues = enumOptions.map(o => `${o.option}`);
      const validValues = intersection(valueArray, allowedValues).join(',');

      return validValues.length > 0
        ? {
            [queryParamName]:
              isSchemaTypeMultiEnum && searchMode ? `${searchMode}:${validValues}` : validValues,
          }
        : {};
    } else {
      // Generic filter - remove empty params
      return paramValue.length > 0 ? { [queryParamName]: paramValue } : {};
    }
  }
  return {};
};

/**
 * Checks filter param value validity.
 *
 * The URL params that are not part of listing.query filters are dropped by default.
 *
 * @param {Object} params Search query params
 * @param {Object} listingFieldsConfig extended data configuration with indexForSearch === true
 * @param {Object} defaultFiltersConfig configuration for default built-in filters.
 * @param {boolean} dropNonFilterParams if false, extra params are passed through.
 */
export const validFilterParams = (
  params,
  listingFieldsConfig,
  defaultFiltersConfig,
  dropNonFilterParams = true
) => {
  const listingFieldFiltersConfig = listingFieldsConfig.filter(
    config => config.filterConfig?.indexForSearch
  );
  const listingFieldParamNames = listingFieldFiltersConfig.map(f =>
    constructQueryParamName(f.key, f.scope)
  );
  const builtInFilterParamNames = defaultFiltersConfig.map(f => f.key);
  const filterParamNames = [...listingFieldParamNames, ...builtInFilterParamNames];

  const paramEntries = Object.entries(params);

  return paramEntries.reduce((validParams, entry) => {
    const [paramName, paramValue] = entry;

    return filterParamNames.includes(paramName)
      ? {
          ...validParams,
          ...validURLParamForExtendedData(
            paramName,
            paramValue,
            listingFieldFiltersConfig,
            defaultFiltersConfig
          ),
        }
      : dropNonFilterParams
      ? { ...validParams }
      : { ...validParams, [paramName]: paramValue };
  }, {});
};

/**
 * Helper to pick only valid values of search params from URL (location)
 * Note: location.search might look like: '?pub_category=men&pub_amenities=towels,bathroom'
 *
 * @param {Object} props object containing: location, listingFieldsConfig, defaultFiltersConfig
 * @returns picked search params against extended data config and default filter config
 */
export const validUrlQueryParamsFromProps = props => {
  const { location, config, params } = props;
  const { listingFields: listingFieldsConfig } = config?.listing || {};
  const { defaultFilters: defaultFiltersConfig } = config?.search || {};
  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const combined_params = {
    ...params,
    ...searchInURL,
  };
  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
  return validFilterParams(combined_params, listingFieldsConfig, defaultFiltersConfig, false);
};

/**
 * Helper to figure out initialValues for Final Form that handles search filters
 *
 * @param {Object} props object containing: location, listingFieldsConfig, defaultFiltersConfig
 * @param {Object} currentQueryParams object containing current state of queryParams (used only when isLiveEdit is false)
 * @returns a function with params queryParamNames, and isLiveEdit.
 *          It's called from FilterComponent and it returns initial values for the filter.
 */
export const initialValues = (props, currentQueryParams) => (queryParamNames, isLiveEdit) => {
  const urlQueryParams = validUrlQueryParamsFromProps(props);

  // Get initial value for a given parameter from state if its there.
  const getInitialValue = paramName => {
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParam = currentQueryParams[paramName];
    const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
    return hasQueryParamInState && !isLiveEdit ? currentQueryParam : urlQueryParams[paramName];
  };

  // Return all the initial values related to given queryParamNames
  // InitialValues for "amenities" filter could be
  // { amenities: "has_any:towel,jacuzzi" }
  const isArray = Array.isArray(queryParamNames);
  return isArray
    ? queryParamNames.reduce((acc, paramName) => {
        return { ...acc, [paramName]: getInitialValue(paramName) };
      }, {})
    : {};
};

/**
 * Some parameters could conflict with sort. If sortConfig defines conflictingFilters,
 * This function checks if they are active and returns "sort" param as null
 *
 * @param {*} searchParams
 * @param {*} listingFieldsConfig
 * @param {*} defaultFiltersConfig
 * @param {*} sortConfig
 * @returns sort parameter as null if sortConfig defines conflictingFilters
 */
export const cleanSearchFromConflictingParams = (
  searchParams,
  listingFieldsConfig,
  defaultFiltersConfig,
  sortConfig
) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    listingFieldsConfig,
    defaultFiltersConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

/**
 * Extract search parameters, including a custom URL params,
 * which are validated by mapping the values to marketplace custom config.
 *
 * @param {Object} params Search query params
 * @param {Object} listingFieldsConfig extended data configuration with indexForSearch === true
 * @param {Object} defaultFiltersConfig configuration for default built-in filters.
 * @param {Object} sortConfig config for sort search results feature
 * @param {boolean} isOriginInUse if origin is in use, return it too.
 */
export const pickSearchParamsOnly = (
  params,
  listingFieldsConfig,
  defaultFiltersConfig,
  sortConfig,
  isOriginInUse
) => {
  const { address, origin, bounds, ...rest } = params || {};
  const boundsMaybe = bounds ? { bounds } : {};
  const originMaybe = isOriginInUse && origin ? { origin } : {};
  const filterParams = validFilterParams(rest, listingFieldsConfig, defaultFiltersConfig);
  const sort = rest[sortConfig.queryParamName];
  const sortMaybe = sort ? { sort } : {};

  return {
    ...boundsMaybe,
    ...originMaybe,
    ...filterParams,
    ...sortMaybe,
  };
};

/**
 * This helper has 2 functions:
 * - It picks search params from Location instance (location.search)
 * - It verifies that those search params are the same as search params in state.
 *   In some cases, search params are referencing previous params
 *   and listings should not be considered loaded.
 *
 * @param {Object} searchFromLocation searchParams from URL (location.search)
 * @param {Object} searchParamsInProps searchParams from store
 * @param {Object} listingFieldsConfig config for listing's extended data
 * @param {Object} defaultFiltersConfig config for default filters
 * @param {Object} sortConfig config for SortBy feature
 * @returns object containing
 *   1. searchParamsInURL (omit pagination 'page' or 'mapSearch'),
 *   2. urlQueryParams (picked valid search params for listing query), and
 *   3. searchParamsAreInSync is true if searchFromLocation and searchParamsInProps match.
 */
export const searchParamsPicker = (
  searchFromLocation,
  searchParamsInProps,
  listingFieldsConfig,
  defaultFiltersConfig,
  sortConfig,
  isOriginInUse
) => {
  const { mapSearch, page, ...searchParamsInURL } = parse(searchFromLocation, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Pick only search params that are part of current search configuration
  const queryParamsFromSearchParams = pickSearchParamsOnly(
    searchParamsInProps,
    listingFieldsConfig,
    defaultFiltersConfig,
    sortConfig,
    isOriginInUse
  );
  // Pick only search params that are part of current search configuration
  const queryParamsFromURL = pickSearchParamsOnly(
    searchParamsInURL,
    listingFieldsConfig,
    defaultFiltersConfig,
    sortConfig,
    isOriginInUse
  );

  // Page transition might initially use values from previous search
  const searchParamsAreInSync =
    stringify(queryParamsFromURL) === stringify(queryParamsFromSearchParams);

  return {
    urlQueryParams: queryParamsFromURL,
    searchParamsInURL,
    searchParamsAreInSync,
  };
};

/**
 * Returns listing fields (extended data configs) grouped into arrays. [primaryConfigArray, secondaryConfigArray]
 * @param {Object} configs listing extended data config
 * @param {Array<String>} activeListingTypes select configs that are marked only for these active listing types
 * @returns Array of grouped arrays. First subarray contains primary configs and the second contains secondary configs.
 */
export const groupListingFieldConfigs = (configs, activeListingTypes) =>
  configs.reduce(
    (grouped, config) => {
      const [primary, secondary] = grouped;
      const { includeForListingTypes, filterConfig } = config;
      const isIndexed = filterConfig?.indexForSearch === true;
      const isActiveListingTypes =
        includeForListingTypes == null ||
        includeForListingTypes.every(lt => activeListingTypes.includes(lt));
      const isPrimary = filterConfig?.group === 'primary';
      return isActiveListingTypes && isIndexed && isPrimary
        ? [[...primary, config], secondary]
        : isActiveListingTypes && isIndexed
        ? [primary, [...secondary, config]]
        : grouped;
    },
    [[], []]
  );

export const createSearchResultSchema = (
  listings,
  mainSearchData,
  intl,
  routeConfiguration,
  config
) => {
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const marketplaceName = config.marketplaceName;
  const { address, keywords } = mainSearchData;
  const keywordsMaybe = keywords ? `"${keywords}"` : null;
  const searchTitle =
    address || keywordsMaybe || intl.formatMessage({ id: 'SearchPage.schemaForSearch' });
  const schemaDescription = intl.formatMessage({ id: 'SearchPage.schemaDescription' });
  const schemaTitle = intl.formatMessage(
    { id: 'SearchPage.schemaTitle' },
    { searchTitle, marketplaceName }
  );

  const schemaListings = listings.map((l, i) => {
    const title = l.attributes.title;
    let category = l.attributes?.publicData?.primary_category;
    if (!category) {
      category = Array.isArray(l.attributes?.publicData?.category) ? l.attributes?.publicData.category[0] : l.attributes?.publicData?.category || 'na';
    }
    let pageID = 'CategoryListingPage';
    let path_params = {
      id: l.id.uuid,
      slug: createSlug(title),
      category,
    };

    const {
      pageID: landingPageID,
      params: landingParams,
    } = landingPagePath(l.id.uuid);
    if (landingPageID) {
      pageID = landingPageID;
      path_params = landingParams;
    }
    const pathToItem = createResourceLocatorString(pageID, routeConfiguration, path_params);
    return {
      '@type': 'ListItem',
      position: i,
      url: `${config.marketplaceRootURL}${pathToItem}`,
      name: title,
    };
  });

  const schemaMainEntity = JSON.stringify({
    '@type': 'ItemList',
    name: searchTitle,
    itemListOrder: 'http://schema.org/ItemListOrderAscending',
    itemListElement: schemaListings,
  });
  return {
    title: schemaTitle,
    description: schemaDescription,
    schema: {
      '@context': 'http://schema.org',
      '@type': 'SearchResultsPage',
      description: schemaDescription,
      name: schemaTitle,
      mainEntity: [schemaMainEntity],
    },
  };
};

export const slugToDescription = (slug, subcategory) => {
  let description = '';
  let meta_description = '';
  let meta_title = '';
  switch (slug) {
    case 'houseplants':
      description = (
        <span>
          Welcome to our extraordinary collection of vibrant houseplants. Hand-selected by experts, each indoor plant arrives robust and healthy, shipped to you safe and sound direct from some favorite houseplant growers. Choose from the best indoor plants on the market. See <a href="https://www.gardeningknowhow.com/featuring/complete-guide-to-houseplants-everything-to-know-about-your-favorite-houseplants" target="_new">our complete houseplant guide</a>!
        </span>
      );
      meta_title = 'Shop Indoor Plants - Houseplants For Sale';
      meta_description = `Shop our extraordinary collection of indoor plants. Whether you're a new houseplant parent or a seasoned collector, we've got something for you.`;
      break;
    case 'plants':
      description = (
        <span>
          Find the very best outdoor plants for your garden, yard, or your favorite containers. These vigorously healthy plants ranges from colorful and dramatically elegant blooms to stately evergreen hedge plants. Add texture, beauty, and functionality to your garden space. <a href="https://www.gardeningknowhow.com/special/spaces/outdoor-green-wall.htm" target="_new">Maybe grow a "green wall?"</a>
        </span>
      );
      meta_description = `Shop the very best outdoor plants from our favorite growers. Find the plants that perfectly suit your garden needs.`;
      meta_title = 'Order Plants Online: The Best Source For Plants';
      break;
    case 'fertilizer-soil':
      description = (
        <span>
          Healthy soil is the foundation of all growing things. We’re featuring only the highest-quality organic fertilizer and soil products because we know soil is the lifeblood of your garden. Once you try any of these products you won’t look back. Feed and nurture your precious plants with the best.
        </span>
      );
      meta_description = `Spring And Fall Bulbs For Your Garden`;
      break;
    case 'tools-accessories':
      description = (
        <span>
          Here’s some fun stuff - garden equipment and supplies like gadgets, bags, trays, sifters and accessories like worm composters, garden lights, and lots of cool extras. Browse this section for perfect solutions to some of the most challenging <a href="https://www.gardeningknowhow.com/garden-how-to/tools/essential-gardening-tools" target="_new">garden tasks</a>. Keep your gardening life fun.
        </span>
      );
      meta_title = 'Shop For Garden Supplies And Equipment That Make Life Easier';
      meta_description = `Browse garden supplies to solve the most challenging issues. Our curated garden equipment selection includes tools, composters, and seed-starting essentials.`;
      break;
    case 'pots-planters':
      description = (
        <span>
          We are thrilled to offer these cutting-edge designs in plant pots. We know firsthand that these are the best-of-the-best planter pots for every type of gardener. Some of our pots for plants can roll, some will self-water, many can be shaped to fit your space, and they’re all super-durable.
        </span>
      );
      meta_title = 'Shop For Pots And Planters That Combine Form And Function';
      meta_description = `Browse our range of stylish and hi-tech pots and planters – from self-watering plant pots to planters on wheels that will elevate your container garden.`;
      break;
    case 'garden-gifts':
      description = (
        <span>
          Tis the season of giving. This winter, embrace the beauty of nature with these expert-curated gifts for your friends, family, loved ones, and maybe even one or two gifts just for you! Start checking off your holiday shopping list with a variety of easy-to-care-for plants, indoor and outdoor gardening tools, clever garden-themed products, and so much more.
        </span>
      );
      meta_title = 'Garden Gifts';
      meta_description = 'These expert-curated gifts for gardeners are sure to please the plant lover in your life! Shop gardening gifts that will satisfy the green thumb on your list.';
      break;
    case 'seeds':
      description = (
        <span>
          Choose from our curated collection from Park Seed for reliably healthy, robust results with your home-grown fragrant herbs, bright flowers and juicy vegetables, all from non-GMO garden seeds. Starting plants from seed is rewarding and fun, and easy to do, and here's all you need to know
        </span>
      );
      meta_title = 'Shop Non-GMO Seeds For A Strong Start To Your Garden';
      meta_description = `Shop our curated seed collection from Park Seed for reliable results. Choose from non-GMO herbs, flowers, and veggies that will start your garden right.`;
      break;
    case 'sustainability-shop':
      description = (
        <span>
          We believe sustainable gardening is the way to a healthier and happier future for plants and people alike. Explore eco-conscious products that support sustainablility in your own garden. Then check out our <a href="https://www.gardeningknowhow.com/featuring/sustainable-gardening-guide-how-to-grow-a-greener-future" target="_new">sustainable gardening guide</a> to learn how to grow a greener future.
        </span>
      );
      meta_title = 'Shop Sustainable Gardening Products For A Greener Garden';
      meta_description = `Shop sustainable gardening products that are good for plants and the planet. Explore our sustainability shop today to start growing a greener future`;
      break;
    case 'small-space-gardening':
      description = (
        <span>
          If you’re an apartment dweller or just living with less garden space than you'd like, we’ve got you. Explore small space gardening products for balcony, porch, or indoor growing that will help you make the most of your space. Then explore our expert tips to <a href="https://www.gardeningknowhow.com/garden-how-to/info/make-a-garden-seem-big.htm" target="_new">make your small garden seem big</a>.
        </span>
      );
      meta_title = 'One-Stop Shop For All Your Small Space Gardening Needs';
      meta_description = `Tiny yards, patios and plots can still rock! Shop for plants, kits and pots to bring all your small space gardening ideas to life.`;
      break;
    case 'indoor-gardening':
      description = (
        <span>
          Do you dream of an indoor garden? Whether you want to grow a houseplant haven or an edible garden indoors, we have what it takes. Explore pots, tools, soil, seed trays, indoor garden systems, and everything you need to coddle your indoor plants, including our <a href="https://www.gardeningknowhow.com/featuring/complete-guide-to-houseplants-everything-to-know-about-your-favorite-houseplants" target="_new">complete guide to houseplant care</a>.
        </span>
      );
      meta_title = 'Grow An Indoor Garden All Year - Shop Everything You Need';
      meta_description = `Do you dream of an indoor garden you can grow all year? Explore tools, trays, indoor garden systems, and everything else you need to make your dream come true`;
      break;
    case 'pollinator-garden':
      description = (
        <span>
          Growing a pollinator garden supports plants and threatened pollinator species. Check out American Beauty's gorgeous plants and Plant By Number's easy kits to turn your garden into a haven for bees, butterflies, birds and other pollinators. Then check out our <a href="https://www.gardeningknowhow.com/featuring/pollinators-in-your-garden" target="_new">complete guide to pollinator gardening</a>.
        </span>
      );
      meta_title = 'Shop Pollinator Garden Plants & Kits For A Bee-utiful Garden';
      meta_description = `Explore our pollinator garden shop for native plants and easy-to-follow plans that will help you grow a bee or butterfly garden that's simply buzzing!`;
      break;
    case 'seasonal-garden-essentials':
      description = (
        <span>
          Step into our beautiful collection for everything you need in your garden for this spring season. Start with our favorite staff picks with soil, seed, flowers and vegetables, and be sure you have starter trays, pots and the proper gardening accessories for every garden project on your list.
        </span>
      );
      break;
    case 'rare-unique-varieties':
      description = (
        <span>
          Explore rare plants and unique varieties you won’t find at your local garden center or big box store. Amateur plant parents and sophisticated collectors alike can find indoor and outdoor plants to add to their space that fits their totally <a href="https://www.gardeningknowhow.com/special/spaces/specialty-gardens-filling-the-needs-of-all-gardeners.htm" target="_new">unique gardening style</a>.
        </span>
      );
      meta_title = `Shop Rare Plants You Won't Find At The Big Box Stores`;
      meta_description = `Shop rare plants and varieties you won’t find at your local garden center or big box store. Explore unique plants that fit your personality and style!`;
      break;
    case 'garden-to-table':
      description = (
        <span>
          Garden to table meals are trending everywhere hungry people gather for fresh, delicious food! Explore seeds and everything else you need to grow fresh organic food that's ready for the table or grill. Then check out our guide about <a href="https://www.gardeningknowhow.com/edible/vegetables/how-to-grow-garden-to-table" target="_new">how to grow garden to table for home cooks</a>.
        </span>
      );
      meta_title = 'Shop Garden To Table Plants & Products For Edible Landscapes';
      meta_description = `Gardening never tasted so good! Shop everything you need to create an edible landscape that goes from garden to table.`;
      break;
    case 'gardening-essentials':
      description = (
        <span>
          These tools are absolute gardening essentials that will help you during every step of the process from seed to harvest. Explore seed starting kits, composters, and other <a href="https://www.gardeningknowhow.com/garden-how-to/tools/essential-gardening-tools" target="_new">essential tools every gardener needs in their tool belt</a>. 
        </span>
      );
      meta_title = 'Gardening Essentials Every Gardener Needs - Shop Now';
      meta_description = `Shop gardening essentials that will help you during every step of the process from seed to harvest. Explore seed starting kits, composters, and more.`;
      break;
    case 'veggie-seeds-for-fall':
      description = (
        <span>
          The garden may be winding down after summer, but fall is a peak time for growing cool-weather vegetables and salad crops. Our curated pick of vegetable seeds includes some of the most delicious, disease-resistant varieties that will add variety to your plate through fall and winter.
        </span>
      );
      meta_title = 'Veggie Seeds For All';
      meta_description = `Discover the best vegetable seeds to plant in fall – including unique flavors and hardy, disease-resistant varieties suitable for beginners.`;
      break;
    case 'flower-seeds-for-fall':
      description = (
        <span>
          Fall is the perfect time to start sowing seeds of many perennial and annual flowers, enabling you to get a head start on the growing season. Our specially selected range of flower seeds to plant in fall will fill your garden beds and containers with a stunning crop of spring and summer blooms.
        </span>
      );
      meta_title = 'Flower Seeds For Fall';
      meta_description = `Shop for the most beautiful flower seeds to plant in fall and get a head start on the growing season – from hardy perennials to showy annuals.`;
      break;
    case 'fall-butterfly-garden':
      description = (
        <span>
          Our native butterfly species become scarcer in the fall, but some are still out seeking food from flowering plants. Meanwhile, their caterpillars may be sheltering on butterfly host plants, getting ready to overwinter. There are myriad beautiful plants that can help these and other precious pollinators to thrive in the fall, as well as seeds that can be planted now to help them in the next growing season.
        </span>
      );
      meta_title = 'Fall Butterfly Garden Necessities';
      meta_description = `Discover stunning fall-flowering plants that are butterfly magnets, serving as food and host plants for precious pollinators. Also find seeds to sow in fall.`;
      break;
    case 'fall-plants-for-autumn-color':
      description = (
        <span>
          Explore colorful fall plants that boast beautiful blooms and fabulous foliage all season! This expert-curated selection is perfect for porch pots, garden beds, and anywhere else you need a pop of color this fall.
        </span>
      );
      meta_title = 'Fall Plants For Amazing Autumn Color';
      meta_description = `Shop colorful fall plants that boast beautiful blooms and fabulous foliage all season! Perfect for pots, garden beds, and anywhere else you need a pop of color.`;
      break;
    case 'winter-plants-for-seasonal-interest':
    case 'winter-plants-for-seasonal':
      description = (
        <span>
          Discover stunning plants that will provide color and interest over the holidays and beyond. Plant up these expert-curated perennial and evergreen beauties in containers to create delightful holiday porch pots, then move them into the garden to become established. In future years, take cuttings to make festive displays and wreaths. 
        </span>
      );
      meta_title = 'Winter Plants for Seasonal Interest';
      meta_description = `Discover stunning plants that will provide color and interest over the holidays and beyond. Plant up these expert-curated perennial and evergreen beauties in containers to create delightful holiday porch pots, then move them into the garden to become established. In future years, take cuttings to make festive displays and wreaths. `;
      break;
    case 'support-small-businesses':
      description = (
        <span>
          Here at Gardening Know How, we love to support small businesses that create unique gifts! That’s why we can’t get enough of Coco & Seed and Bee Cups, two woman-owned businesses that make gardening simpler and more sustainable. Tishana Richards of Coco & Seed makes indoor gardening easy with eco-friendly Mason jar kits, which you may recognize from Oprah’s Favorite Things and the TODAY Show. While Jen Rose’s charming Bee Cups combine science, sustainability, and artistry into tiny watering stations that make a big impact on pollinators.
        </span>
      );
      meta_title = 'Shop Small Businesses For Unique Gifts';
      meta_description = `Support small businesses by shopping these products made by woman-owned companies.`;
      break;
    case 'stocking-stuffer-ideas':
      description = (
        <span>
          Treat the plant lover in your life to our hand-picked selection of stocking stuffer ideas for gardeners. Whatever your budget, we’ve got the perfect gifts – from tools to make their life easier, to stylish accessories, and project kits that will inspire them to get growing.
        </span>
      );
      meta_title = 'Stocking Stuffer Ideas For Gardeners';
      meta_description = `Shop stocking stuffer ideas for gardeners that will fuel their passion for plants – from quality tools to fun accessories, and growing kits to suit all budgets.`;
      break;
    case 'herb-garden-gifts':
      description = (
        <span>
          Treat foodies and gardeners to the gift of organic herbs. Our selection of herb garden gifts includes everything they need to start growing – from seed-starting bundles and growing kits to planters and accessories. Plus, fill their stocking with the most aromatic herb seeds to inspire their culinary adventures.
        </span>
      );
      meta_title = 'Herb Garden Gifts';
      meta_description = `Shop for herb garden gifts that will delight foodies and gardeners. Discover growing kits, organic seeds, perfect planters, and the best tools for the job.`;
      break;
    case 'seed-starting-gifts':
      description = (
        <span>
          Share the gift of growing beautiful flowers or delicious organic herbs and vegetables with our curated pick of seed-starting essentials. Treat budding gardeners and seasoned pros to the ultimate growing kit, or inspire them with seed packets and collections that make ideal stocking stuffers.
        </span>
      );
      meta_title = 'Seed Starting Gifts For Gardeners';
      meta_description = `Shop for seed gifts to share the joy of growing beautiful flowers or delicious produce. Choose from quality seed-starting kits and inspiring seed collections.`;
      break;
    case 'Seed-starting-essentials':
      description = (
        <span>
          A great garden starts here! Explore our expert-curated collection of seed starting essentials that will help you start your garden right. Shop seeds, seed starting trays, tools, and everything you need to grow your best garden ever.
        </span>
      );
      meta_title = 'Seed Starting Essentials';
      meta_description = `A great garden starts here! Explore our expert-curated collection of seed starting trays, tools, and all the essentials you need to grow your best garden ever.`;
      break;
    case 'wildlife-lover-gifts':
      description = (
        <span>
          Save the bees! And the butterflies and the birds. These pollinator and wildlife-friendly gifts are perfect for the nature lover in your life, or for yourself.
        </span>
      );
      meta_title = 'Gifts for Wildlife and Pollinator Lovers';
      meta_description = `Shop gifts for pollinator and wildlife lovers and do your part to keep bees, butterflies, and birds happy and healthy.`;
      break;
    case 'plants-we-love':
      description = (
        <span>
          We <FavoriteIcon style={{ color: 'red' }} /> these plants! Shop highly-recommended garden plants, houseplants, and seeds our experts adore. Whether you want beautiful blooms, tasty herbs, or unique indoor plants, there's something for every garden. Then take a look at these <a href="https://www.gardeningknowhow.com/garden-how-to/info/expert-gardening-techniques" target="_new">expert techniques to help you raise your growing game</a>.
        </span>
      );
      meta_title = 'Explore Expert Recommended Garden Plants &amp; Houseplants';
      meta_description = `Shop plants gardening experts adore! Explore highly-recommended garden plants and houseplants even the pros want to take home.`;
      break;
    default:
      description = (
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      Vulputate dignissim suspendisse in est. Id consectetur purus ut faucibus.
      Pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat.
      Tincidunt vitae semper quis lectus nulla at volutpat diam ut. Odio morbi quis commodo odio aenean sed adipiscing diam donec.
        </span>
      );
      break;
  }
  switch (subcategory) {
    case 'bulbs':
      description = (
        <span>
          Bulbs are a beautiful way to dress up your garden, yard, or your favorite  flower bulb containers. Flower bulbs provide repeated beauty year after year and are easy to separate and propagate. <a href="https://www.gardeningknowhow.com/ornamental/bulbs/bgen/how-long-for-bulbs-to-grow.htm" target="_new">Plant bulbs</a> to fill your space with color or brighten up spots in your borders., flower beds or yard.
        </span>
      );
      meta_title = `Spring And Fall Bulbs For Your Garden`;
      meta_description = `Whether you plant bulbs in the spring or the fall, you'll find the perfect flower bulbs for your garden here.`;
      break;
    case 'trees-shrubs':
      description = (
        <span>
          Green Promise Farms brings us the loveliest healthy trees and shrubs from American Beauties. Every plant in this special selection will fill the perfect spot in your yard or garden with vitality, beauty and texture. Curated best of the best. And learn here how to plant and care for your favorites.
        </span>
      );
      meta_title = `Trees For Sale - Buy Trees And Shrubs Online Here`;
      meta_description = `Shop the finest trees and shrubs from our trusted growers. Trees and shrubs ship healthy and ready to plant.`;
      break;
    case 'perennial-plants':
      description = (
        <span>
          Perennial plants are such a happy bargain. These perennial flowers and plants come back year after year with increased vigor and beauty. Browse through this collection of beautiful blooms and textures, perfect for your garden or containers. <a href="https://www.gardeningknowhow.com/ornamental/flowers/fgen/gardening-with-perennials.htm" target="_new">Here's some great info on how to design a perennial garden</a>.
        </span>
      );
      meta_title = `Buy Perennial Flowers Online`;
      meta_description = `We've gathered perennial flowers from our most trusted sources. Shop perennials now for beauty that comes back again and again.`;
      break;
    case 'edibles':
      description = (
        <span>
          Edible plants in your garden, containers or landscape lend a delicious as well as a fascinating addition to your garden design. It's a delight to pluck your own fruits from a plant that also dresses up your space. Dig into more <a href="https://www.gardeningknowhow.com/edible/vegetables/vgen/edible-landscaping-mixing-vegetables-and-herbs-with-flowers.htm" target="_new">edible landscaping</a> ideas.
        </span>
      );
      meta_title = `Edible Plants For Sale - Start Your Veggie Garden Today`;
      meta_description = `Browse our selection of edible plants and seeds including tomatoes, peppers, beans and more.`;
      break;
    case 'natives':
      description = (
        <span>
          Having lots of native plants and pollinator flowers thriving in your yard or garden makes a great contribution to the health of planet Earth. Let these vibrant native plants grow naturally and give your local bees and butterflies a treat. Learn more about <a href="https://www.gardeningknowhow.com/garden-how-to/info/native-plant-information.htm" target="_new">why native plants are important</a>.
        </span>
      );
      meta_title = `Native Plant Store - Best Native Plants For The Environment`;
      meta_description = `Native plants and pollinator plants are the foundation of a healthy environment. Do your part by shopping plants native to your area.`;
      break;
    case 'roses':
      description = null;
      meta_title = `Buy Roses Here – Shop For Your Quintessential Rose Garden`;
      meta_description = `Browse these specially curated rose selections from trusted suppliers. Shop for gorgeous garden roses including climbers, hybrids and shrub varieties.`;
      break;
    case 'ferns':
      description = (
        <span>
          Add texture and interest with a fern plant grown in a pot or placed in the ground among other landscape plants. The ferns for sale here are part of the American Beauties Native Plant line brought to you by Green Promise Farms. And they are beautiful. Here are some easy care tips.
        </span>
      );
      meta_title = 'Shop Fabulous Fern Plants For Beds And Containers';
      meta_description = `Browse our shop selection of fern plants that add easy texture to quiet corners. These ferns for sale turn tricky places into pretty green points of interest.`;
      break;
    case 'grass':
      description = (
        <span>
          Choose the perfect ornamental grasses to emphasize your landscape. We've selected the most impressive textures and colors to add drama and beauty to your borders, garden spaces or containers. <a href="https://www.gardeningknowhow.com/ornamental/foliage/ornamental-grass/sensational-borders-with-ornamental-grass.htm" target="_new">Check out some ideas</a>.
        </span>
      );
      meta_title = 'Buy Ornamental Grasses To Add Drama And Texture - Shop Now';
      meta_description = `Shop for gorgeous grasses with plants to add height, drama and texture. Specially selected ornamental grasses for sale help you round off a landscape in style.`;
      break;
    case 'vines':
      description = (
        <span>
          Showy flowering vine plants add interest and fragrance to any space where a  vine can creep, climb and grow. Vines can add privacy, cover an awkward wall or fence and enhance the vertical space in your garden. Here's <a href="https://www.gardeningknowhow.com/ornamental/vines/ovgen/tips-for-growing-exotic-flowering-vines.htm" target="_new">more about growing flowering vine plants</a>.
        </span>
      );
      meta_title = 'Shop For Flowering Vine Plants For Pollinators And Privacy';
      meta_description = `Browse our selection of fragrant and flowering vines to fill vertical space. Shop vine plants from trusted growers to boost privacy, add color and attact wildlife.`;
      break;
    case 'raised-beds':
      description = (
        <span>
          Shop here for the perfect raised bed to fit every space and gardening need. We have direct experience with the quality and durability of these versatile metal raised garden beds by Vego. Designed to conform to any shape in your yard, this cutting edge in raised garden beds, is a <a href="https://www.gardeningknowhow.com/garden-how-to/projects/vego-raised-beds.htm" target="_new">real game changer</a>.
        </span>
      );
      break;
    case 'rolling-planters':
      description = (
        <span>
          At some point, every gardener has wished for a rolling planter, either for convenience, to change sun exposure or just relocate your plants. A planter on wheels is every gardener's dream for portability, and Vego offers several designs that also water themselves!
        </span>
      );
      meta_title = 'Shop Rolling Planters For A Garden That Grows Where You Go';
      meta_description = `Shop rolling planters from Vego for a garden that grows with you, wherever you go. Make gardening more convenient with a modern, mobile planter on wheels.`;
      break;
    case 'planters':
      description = (
        <span>
          Browse this collection of our favorite self-watering planters. The range and variety of high-quality indoor and outdoor planters means you'll find the best of whatever you need for each of your best plants. Make your life easier.
        </span>
      );
      meta_title = 'Shop High-Quality Planters For Indoor & Outdoor Growing';
      meta_description = `Shop planters for indoor and outdoor growing. Explore high-quality, self-watering options that reduce water use and save you time.`;
      break;
    case 'seed-starting':
      description = (
        <span>
          Love growing plants from seed? Seedling starting trays are the handiest way to keep track and give your new baby plants a healthy start. Starter trays can be bottom watered, stacked and covered for a quick and safe start for all your new plants. Here's <a href="https://www.gardeningknowhow.com/featuring/seed-starting-for-beginners-everything-to-know-before-you-start-seeds" target="_new">everything you need to know</a>.
        </span>
      );
      meta_title = 'Buy Seed Starter Trays And Kits For Growing Healthy Plants';
      meta_description = `Shop for everything you need to begin your seed starter journey – from seed starting trays to complete kits. Your plants will have the best possible start.`;
      break;
    case 'garden-tools':
      description = (
        <span>
          Extra fun garden tools to make your gardening experience a great part of your day. Check out the items you didn't know you needed, like a garden bag, a <a href="https://www.gardeningknowhow.com/garden-how-to/tools/using-a-hori-hori-knife.htm" target="_new">Hori Hori knife</a> and some thoughtful gifts for you or your favorite gardener. You'll wonder how you got by without them.
        </span>
      );
      meta_title = 'Shop For Essential Gardening Tools To Elevate Everyday Tasks';
      meta_description = `Browse gardening tools that will bring joy to your hobby every day and make perfect gifts. From tool boxes and bags, to the iconic hori hori knife.`;
      break;
    case 'composters':
      description = (
        <span>
          Turn your organic waste into rich, nutrient-dense garden bed soil. Fill this worm composting bin with waste and worms, bury it, and let nature take over for the best compost your garden ever had. It's <a href="https://www.gardeningknowhow.com/composting/vermicomposting/worms-for-vermicomposting.htm" target="_new">easy and rewarding</a>.
        </span>
      );
      meta_title = 'Buy Composter Essentials To Make Your Own Organic Compost';
      meta_description = `Make your garden more sustainable with a worm composting bin. This natural solution is an investment in soil health and will produce your best ever compost.`;
      break;
    case 'planning-design':
      description = (
        <span>
          Landscape planning can be artful and effective with pre-printed landscape planners that makes flower garden designs a breeze. Kits for every combination of perennial landscaping customize your planting space with precision. Make it easy to <a href="https://www.gardeningknowhow.com/garden-how-to/design/lideas/landscape-design-problems.htm" target="_new">design a cohesive garden bed</a> without breaking the bank.
        </span>
      );
      meta_title = 'Buy Landscape Planning Kits To Create New Beds And Borders';
      meta_description = `Realize your garden design dreams with our landscape planning kits. From flower garden designs to hardy perennials, these pre-planned gardens have all you need.`;
      break;
    case 'vegetables':
      description = (
        <span>
          Veggie seeds from Park Seed make it easy (and affordable) to grow the garden of your dreams! Choose from dozens of non-GMO seeds to find the plants that suit your palate. Then take a look at our complete guide to vegetable gardening to learn how to grow your biggest bumper crop ever.
        </span>
      );
      meta_title = 'Vegetable Seeds For Sale - Start Your Kitchen Garden Here';
      meta_description = `Browse our non-GMO veggie seeds to kickstart the kitchen garden of your dreams. Shop vegetable seeds that cover all the bases from beets to zucchinis. `;
      break;
    case 'herbs':
      description = (
        <span>
          Add a little spice to your garden with herb seeds from Park Seed! Explore tasty varieties that will enhance any meal with fresh, homegrown flavor and fragrant herbs you can use for cooking or crafts. Then check out our beginner herb care guide to learn how to go from seed to plate.
        </span>
      );
      meta_title = 'Buy Fragrant Herb Seeds To Enhance Your Edible Garden';
      meta_description = `Try these specially curated herb seeds for sale from trusted vendors to fill your plot with fragrant edibles and savory seasonings`;
      break;
    case 'flowers':
      description = (
        <span>
          Flower seeds from Park Seed are sure to make your garden shine! Explore dozens of different flowering plant seeds from alyssum to zinna and find the perfect posies for your garden. Then check out our complete guide to growing flowers to learn how to get beautiful blooms all season.
        </span>
      );
      meta_title = 'Flower Seeds For Sale - Shop Beautiful Blooms From Park Seed';
      meta_description = `Shop flower seeds from Park Seed that are sure to make your garden shine! Explore dozens of different varieties from alyssum to zinnia.`;
      break;
    default:
      break;
  }
  return ({
    pageDescription: description,
    metaTitle: meta_title,
    metaDescription: meta_description,
  });
};
