import React from 'react';
// import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { object, string, func, bool } from 'prop-types';
import classNames from 'classnames';
const { types } = require('sharetribe-flex-sdk');
// import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  createSlug,
  landingPagePath,
} from '../../util/urlHelpers';

const { Money } = types;

import css from './CartItem.module.css';

import {
  Form,
  H4,
  ResponsiveImage,
  FieldSelect,
  NamedLink,
} from '../../components';

const quantityForm = cart_item_data => (formRenderProps) => {
  const {
    className,
    rootClassName,
    handleSubmit,
    handleChange = (e) => {},
    updateCartInProgress,
    // form: formApi,
    values,
  } = formRenderProps;
  const {
    quantity,
  } = values;
  const classes = classNames(rootClassName || css.root, className);

  const handleQuantityChange = (e) => {
    const data = {
      value: parseInt(e || 0, 10),
      cart_item_data,
    }
    handleChange(data);
  };

  const quantities = [];
  for (let i = 0; i <= quantity; i++) {
    quantities.push(i);
  }

  return (
    <Form
      className={classes}
      onSubmit={handleSubmit}
    >
      <FieldSelect
        id="quantity"
        className={css.quantityField}
        name="quantity"
        onChange={handleQuantityChange}
        disabled={updateCartInProgress}
      >
        {quantities.map(quantity => (
          <option key={quantity} value={quantity}>
            { quantity || 'remove' }
          </option>
        ))}
      </FieldSelect>
    </Form>
  );
}

const CartItemComponent = props => {
  const {
    data,
    // cartDiscountData,
    className,
    rootClassName,
    // intl,
    // config,
    priceData,
    canUpdateQuantity,
    handleQuantityChange,
    updateCartInProgress,
  } = props;
  if (!data?.item_data) {
    return null;
  }
  const {
    quantity,
    shipping_price,
    shipping_table_applies = false,
    variant,
    item_data,
  } = data || {};

  const classes = classNames(rootClassName || css.root, className);
  const {
    id,
    attributes,
    images,
    author,
  } = item_data;

  const {
    title,
    description,
    price,
    publicData,
  } = attributes || {};

  const {
    shipping_disclaimer,
    shippingPriceVisible: shippingPriceVisibleFromPublicData,
  } = publicData || {};

  const shippingPriceVisible = shippingPriceVisibleFromPublicData || shipping_table_applies;
  // const shippingPriceVisible = shippingPriceVisibleFromPublicData;

  let category = publicData?.primary_category;
  if (!category) {
    category = Array.isArray(publicData?.category) ? publicData.category[0] : publicData?.category || 'na';
  }
  
  // const authorName = author?.attributes?.profile?.publicData?.storeName || author?.attributes?.profile?.displayName;
  // const authorID = author?.id?.uuid;
  const photo_variants_json = attributes?.publicData?.photo_variants || null;
  const photo_variants = photo_variants_json ? JSON.parse(photo_variants_json) : null;
  let image = Array.isArray(images) && images.length > 0 ? images[0] : null;
  if (
    Array.isArray(photo_variants)
    && photo_variants.length > 0
    && Array.isArray(images)
    && images.length > 0
    && variant?.value
  ) {
    const {
      value: variant_value,
      subvariant_value,
    } = variant || {};
    if (subvariant_value) {
      const subvariant_key = `${variant_value}__${subvariant_value}`;
      const found_photo_for_variant = photo_variants.find(pv => Array.isArray(pv?.variant) && pv.variant.includes(subvariant_key));
      if (found_photo_for_variant) {
        const found_image = images.find(i => i.id.uuid === found_photo_for_variant.id);
        if (found_image) {
          image = found_image;
        }
      }
      // console.log('variants', variants);
    } else {
      const found_photo_for_variant = photo_variants.find(pv => Array.isArray(pv?.variant) && pv.variant.includes(variant_value));
      if (found_photo_for_variant) {
        const found_image = images.find(i => i.id.uuid === found_photo_for_variant.id);
        if (found_image) {
          image = found_image;
        }
      }
    }
    // console.log('variants', variants);
  }

  /*
  if (cartDiscountData) {
    if (
      cartDiscountData?.applies_to_all_merchants
      || (
        Array.isArray(cartDiscountData?.merchant_ids)
        && cartDiscountData.merchant_ids.includes(authorID)
      )
    ) {
      const discount_percentage = parseFloat(cartDiscountData?.discount_percentage || 0);
      price.amount = Math.round(price.amount - (price.amount * discount_percentage));
    }
  }
  */

  const {
    formattedPrice,
    // priceTitle,
  } = priceData(price);
  const reformatted_item_price = shippingPriceVisible ? parseFloat(price?.amount || 0) * (quantity || 1)
    : parseFloat(price?.amount || 0) * (quantity || 1) + parseFloat(shipping_price || 0)
  const {
    formattedPrice: formattedTotalItemPrice,
  } = priceData(new Money(reformatted_item_price, 'USD'));

  const {
    formattedPrice: formattedShippingPrice,
  } = priceData(new Money(parseFloat(shipping_price || 0), 'USD'));

  const variantPrefix = 'scaled-large';
  const img_variants = image
    ? Object.keys(image?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const handleSubmit = (e) => {
    console.log('e submit', e);
  };

  // console.log(author);
  let pageID = 'CategoryListingPage';
  let params = {
    id: id?.uuid,
    slug: createSlug(title),
    category,
  };

  const {
    pageID: landingPageID,
    params: landingParams,
  } = landingPagePath(id?.uuid);
  if (landingPageID) {
    pageID = landingPageID;
    params = landingParams;
  }
  // const to = { search: stringify(searchParams) };

  return (
    <div className={classes}>
      {/*
      <div className={css.soldBy}>
        Sold By
        <NamedLink
          className={css.authorNameLink}
          name="ProfilePage"
          params={{
            id: author?.id?.uuid || '',
          }}
        >
          {authorName}
        </NamedLink>
      </div>
      */}
      <div className={css.layout}>
        <div className={css.imageContainer}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            image={image}
            alt={title}
            variants={img_variants}
          />
        </div>
        <div className={css.detailsContainer}>
          <NamedLink
            name={pageID}
            params={params}
            // to={to}
          >
            <H4 className={css.title}>{title}</H4>
          </NamedLink>
          {
            variant?.subvariant_value
            && (
              <div key={variant.name}>
                {variant.label}: {variant.name} ({variant.subvariant_name})
              </div>
            )
          }
          {
            variant?.value
            && !variant?.subvariant_value
            && (
              <div key={variant.name}>
                {variant.label}: {variant.name}
              </div>
            )
          }
          {
            shipping_disclaimer
            && (
              <div className={css.description}>{shipping_disclaimer}</div>
            )
          }
        </div>
        <div className={css.quantityContainer}>
          {
            canUpdateQuantity
            && (
              <>
                <div>Shipping Quantity</div>
                <div>
                  <FinalForm
                    onSubmit={handleSubmit}
                    render={quantityForm(data)}
                    initialValues={{ quantity }}
                    className={css.quantityForm}
                    handleChange={handleQuantityChange}
                    updateCartInProgress={updateCartInProgress}
                  />
                </div>
              </>
            )
          }
          {
            !canUpdateQuantity
            && (
              <div>Shipping Quantity: {quantity}</div>
            )
          }
          
        </div>
        <div className={css.priceContainer}>
          <h4 className={css.price}>{formattedTotalItemPrice}</h4>
          {/*
            quantity > 1
            && (
              <div>
                {formattedPrice} each
              </div>
            )
            */}
          {
            !!shipping_price
            && shippingPriceVisible
            && false // disable
            && <h4 className={css.shippingPrice}>{formattedShippingPrice} shipping</h4>
          }
        </div>
      </div>
    </div>
  );
};

CartItemComponent.defaultProps = {
  className: null,
  rootClassName: null,
  canUpdateQuantity: false,
  handleQuantityChange: () => {},
  updateCartInProgress: false,
  cartDiscountData: null,
};

CartItemComponent.propTypes = {
  className: string,
  rootClassName: string,
  data: object.isRequired,
  // intl: intlShape.isRequired,
  // config: object.isRequired,
  priceData: func.isRequired,
  canUpdateQuantity: bool,
  handleQuantityChange: func,
  updateCartInProgress: bool,
  cartDiscountData: object,
};

export default CartItemComponent;
