import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import {
  H2,
  H4,
} from '../../../../components';

import css from './SectionReencleComposter.module.css';

const ReencleContentNews = props => {
  const {
    sectionId,
  } = props;

  const contentArray = [
    {
      src: '/static/reencle/itn_today_logo.cropped.png',
      label: 'Today',
      content: (
        <>
          <p>"The Reencle seems to be the best home composter on th market, since it uses fermentation while most other home composters just dehydrate and chop up food scraps into smaller particles."</p>
          <img src="/static/reencle/what_Icon_03_Fish.png" className={css.newsImgBottomRight} alt="Today" />
        </>
      ),
    },
    {
      src: '/static/reencle/itn_best_products_logo.cropped.png',
      label: 'Best Products',
      content: (
        <>
          <p>"The no.1 rated home composter. Finally, your leftovers don't have to become garbage disposal fodder. It can't handle everything. But yes, go ahead and throw in that cheese."</p>
          <img src="/static/reencle/what_Icon_08_Cheese.png" className={css.newsImgBottomRight} alt="Best Products" />
        </>
      ),
    },
    {
      src: '/static/reencle/itn_compost_mag_logo.cropped.png',
      label: 'Compost Magazine',
      content: (
        <>
          <p>"Food that would have rotted in a kitchen bin... can now be transformed into a valuable nutrient for your plants. In short, for every frustrated aprtment compsoter out there, there's now a solution. And that's just BRILLIANT."</p>
          <img src="/static/reencle/what_Icon_07_Sausage.png" className={css.newsImgTopRight} alt="Compost Magazine" />
        </>
      ),
    },
    {
      src: '/static/reencle/itn_hollywood_logo.cropped.png',
      label: 'The Hollywood Reporter',
      content: (
        <>
          <p>"Earth day essentials celebrate earth day by making eco-friendly choices at home."<br />- Reencle</p>
          <img src="/static/reencle/what_Icon_02_Vegetables.png" className={css.newsImgBottomRight} alt="Hollywood Reporter" />
        </>
      ),
    },
  ];

  return (
    <div>
      <H2 className={classNames(css.tabSectionTitle, css.noMarginBottom)}>In The News</H2>
      <div id={sectionId} className={css.tabContentContainer}>
        <div className={css.layout4}>
          {
            contentArray.map((content_data) => {
              const {
                src: img_src,
                content,
                label,
              } = content_data || {};
              return (
                <div className={css.layout4Item}>
                  <div className={css.newsItem}>
                    <div className={css.newsImgContainer}>
                      <img src={img_src} className={css.responsiveImage} alt={label} />
                    </div>
                    {content}
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

ReencleContentNews.defaultProps = {
  sectionId: null,
};

ReencleContentNews.propTypes = {
  sectionId: string,
};

export default ReencleContentNews;
