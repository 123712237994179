import React from 'react';
import { string } from 'prop-types';
// import classNames from 'classnames';

import {
  H2,
  H4,
} from '../../../../components';

import css from './SectionReencleComposter.module.css';

const ReencleContentSpecs = props => {
  const {
    sectionId,
  } = props;

  const contentArray = [
    {
      title: 'Dimensions',
      content: (
        <>
          12”D x 13”W x 18.4”H(14L)
        </>
      ),
    },
    {
      title: 'Package Dimensions',
      content: (
        <>
          14.1“D x 14.9”W x 22.2”H
        </>
      ),
    },
    {
      title: 'Product Weight',
      content: (
        <>
          20.1 lbs
        </>
      ),
    },
    {
      title: 'Compost Capacity',
      content: (
        <>
          <div>
            Optimum per day 1.5 lbs
          </div>
          <div>
            Maximum per day 2.2 lbs
          </div>
        </>
      ),
    },
    {
      title: 'Power',
      content: (
        <>
          AC adaptor
        </>
      ),
    },
    {
      title: 'Voltage',
      content: (
        <>
          120V / 60Hz
        </>
      ),
    },
    {
      title: 'Power Requirements',
      content: (
        <>
          110V / 50~60Hz
        </>
      ),
    },
    {
      title: 'Power Cord',
      content: (
        <>
          1.8m / 5.9ft
        </>
      ),
    },
    {
      title: 'Chamber Material',
      content: (
        <>
          PP (PFOA & PFOS & BPA Free)
        </>
      ),
    },
    {
      title: 'Certifications',
      content: (
        <>
          KC / CE / Patent of Microoganism Various certifications for functions(Decomposition, Salt, Bacteria)
        </>
      ),
    },
  ];

  return (
    <>
      <H2 className={css.tabSectionTitle}>Technical Specifications</H2>
      <div id={sectionId} className={css.tabContentContainer}>
        <div className={css.layout40_60}>
          <div className={css.layout40_60Item}>
            <img src="/static/reencle/tech_spec_product-52.png" className={css.responsiveImage} alt="Technical Specifications" />
          </div>
          <div className={css.layout40_60Item}>
            <table border="0">
            {
              contentArray.map((faq_data) => {
                const {
                  title,
                  content,
                } = faq_data;
                return (
                  <tr className={css.specRow}>
                    <td className={css.specLabel}>
                      {title}
                    </td>
                    <td className={css.specContent}>
                      {content}
                    </td>
                  </tr>
                );
              })
            }
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

ReencleContentSpecs.defaultProps = {
  sectionId: null,
};

ReencleContentSpecs.propTypes = {
  sectionId: string,
};

export default ReencleContentSpecs;
