import React from 'react';
import { string } from 'prop-types';
// import classNames from 'classnames';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  H2,
  H4,
} from '../../../../components';

import css from './SectionReencleComposter.module.css';

const ReencleContentFAQ = props => {
  const {
    sectionId,
  } = props;

  const faqArray = [
    {
      title: 'What consumable items do I need to buy periodically?',
      content: (
        <>
          <span>1. Filter</span>
          <p className={css.noTopMargin}>
            Our kit includes two filters, replace every 9-12 months due to a 131°F internal temperature that eliminates odor-causing bacteria. Our microbes also prevent harmful bacteria, ensuring durability for 1-2 years and outlasting dehydrators in the market
          </p>
          <span>2. Microbes</span>
          <p className={css.noTopMargin}>
            No need for regular microbe purchases; they naturally reproduce. After buying the machine, extra bags are unnecessary unless there's a cultivation issue. As decomposition occurs, microbes naturally increase
          </p>
        </>
      )
    },
    {
      title: 'How can I utilize microorganisms as compost?',
      content: (
        <>
          <p className={css.noTopBottomMargin}>To use microorganisms for composting:</p>
          <ol className={css.noTopMargin}>
            <li>Remove compost with the provided scoop, keeping the MIN line inside the unit.</li>
            <li>Sift to remove undecomposed pieces.</li>
            <li>Mix 1 part compost with 4 parts soil.</li>
            <li>Cure the mixture for 3 weeks in a breathable container.</li>
          </ol>
          <p>It's ready when it's dark, crumbly, and smells earthy.</p>
        </>
      )
    },
    {
      title: 'Is it okay to turn off the product when traveling?',
      content: (
        <>
          <p className={css.noTopMargin}>For your convenience, we've outlined instructions tailored to your travel duration:</p>
          <p className={css.noBottomMargin}>For trips under a week</p>
          <ol className={css.noTopMargin}>
            <li>Keep the power cord plugged in.</li>
            <li>Moisten the microorganisms on your departure day.</li>
          </ol>
          <p className={css.noBottomMargin}>For longer trips exceeding a week</p>
          <ol className={css.noTopMargin}>
            <li>Stop adding food waste 2-3 days before leaving.</li>
            <li>Use the "dry" function for 2-3 days to keep microorganisms dry.</li>
            <li>Unplug the power cord when you leave.</li>
            <li>Store microorganisms in a sealed ziplock bag in a cool, dark place.</li>
            <li>Upon return, reconnect power, add microorganisms, and activate them with 500ml of water after 6 hours.</li>
          </ol>
        </>
      )
    },
    {
      title: 'When should I empty the microorganisms?',
      content: (
        <p className={css.noTopMargin}>
          The Max line at the top of the Reencle chamber indicates when to remove the byproduct. You can choose to remove it at the glowing white (LED) line for more frequent emptying, about every two weeks, or at the "Min" line for less frequent removal, about monthly. The method depends on your preference and needs.
        </p>
      )
    },
  ];

  return (
    <>
      <H2 className={css.tabSectionTitle}>FAQs</H2>
      <div id={sectionId} className={css.tabContentContainer}>
      {
        faqArray.map((faq_data) => {
          const {
            title,
            content,
          } = faq_data;
          return (
            <Accordion
              elevation={0}
              className={css.accordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={css.accordionSummary}
                classes={{
                  content: css.accordionSummaryContent,
                }}
              >
                <b>{title}</b>
              </AccordionSummary>
              <AccordionDetails
                className={css.accordionDetails}
              >
                {content}
              </AccordionDetails>
            </Accordion>
          );
        })
      }
      </div>
    </>
  );
};

ReencleContentFAQ.defaultProps = {
  sectionId: null,
};

ReencleContentFAQ.propTypes = {
  sectionId: string,
};

export default ReencleContentFAQ;
