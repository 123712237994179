import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import FavoriteIcon from '@mui/icons-material/Favorite';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  let additional_classname = null;
  if (sectionId === 'giftguidehero') {
    additional_classname = css.giftguidehero;
  }

  const scrollToSection = (evt, sectionId) => {
    evt.preventDefault();
    evt.stopPropagation();
    const selector = `#${sectionId}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  const additionalContent = () => {
    if (sectionId === 'giftguidehero_disabled') {
      return (
        <div className={css.buttonContainer}>
          <a className={css.navButton} href="#gg24-combo-bright-cheery" onClick={(e) => scrollToSection(e, 'gg24-combo-bright-cheery')}>
            Cheery &amp; Bright
            <div>Gifts for the Home</div>
          </a>
          <a className={css.navButton} href="#gg24-combo-small" onClick={(e) => scrollToSection(e, 'gg24-combo-small')}>
            Good Things in
            <div>Small Packages</div>
          </a>
          <a className={css.navButton} href="#gg24-combo-smiles" onClick={(e) => scrollToSection(e, 'gg24-combo-smiles')}>
            Spread Smiles
            <div>and Good Vibes</div>
          </a>
          <a className={css.navButton} href="#gg24-combo-magic" onClick={(e) => scrollToSection(e, 'gg24-combo-magic')}>
            Attract Magic
            <div>to your Garden</div>
          </a>
          <a className={css.navButton} href="#gg24-combo-mood" onClick={(e) => scrollToSection(e, 'gg24-combo-mood')}>
            Mood-boosting
            <div>Gifts</div>
          </a>
          <a className={css.navButton} href="#gg24-combo-planet" onClick={(e) => scrollToSection(e, 'gg24-combo-planet')}>
            For People Who
            <div><FavoriteIcon />The Planet</div>
          </a>
        </div>
      );
    }
    return null;
  }

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root, additional_classname)}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {additionalContent()}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHero;
