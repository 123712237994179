import React from 'react';
import { bool, func, node, object, shape, string, array, number } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

/*
import Field, { hasDataInFields } from '../../Field';
import {
  ListingCard, NamedLink,
} from '../../../../components';
*/

import SectionContainer from '../SectionContainer';
import ListingLandingSection from '../../../ListingPage/ListingLandingSection';
import css from './SectionListing.module.css';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionListingComponent = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    // description,
    appearance,
    // callToAction,
    options,
    listings = [],
    // numCols = 3,
    // viewAllCategory,
    // isSquare,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const isForFeatured =  Array.isArray(listings) && listings.length <= 2;

  // const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const classes = isForFeatured ? classNames(rootClassName || css.root, className, css.blankBG)
    : classNames(rootClassName || css.root, className);

  const additionalContent = (
    <div className={css.additionalContent}>
      <p style={{ fontWeight: 600, marginTop: 10, marginBottom: 0 }}>The odor-free mess-free solution to food waste:</p>
      <ul style={{ listStyleType: 'disc', listStylePosition: 'inside', marginTop: 0 }}>
        <li style={{ fontSize: '14px', fontWeight: 600 }}>Exclusive Offer! No Waste Kitchen Gardening Course FREE with purchase.</li>
        <li style={{ fontSize: '14px' }}>Makes real compost, not dehydrated food mulch</li>
        <li style={{ fontSize: '14px' }}>Complete odor control = no smelly kitchen</li>
        <li style={{ fontSize: '14px' }}>Efficient - no messy and time consuming cycles</li>
        <li style={{ fontSize: '14px' }}>Whisper quiet</li>
        <li style={{ fontSize: '14px' }}>Save money! Costs less to operate than competitors</li>
      </ul>
    </div>
  );

  return (
    <SectionContainer
      id={sectionId}
      className={classes}
      rootClassName={classNames(rootClassName || css.root)}
      contentClassName={css.sectionContent}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.contentWrapper}>
        {/*
        hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          </header>
        ) : null
        */}
        <div
          className={classNames(
            css.blockContainer,
            defaultClasses.blockContainer,
            // getColumnCSS(numColumns),
            {
              [css.noSidePaddings]: true,
            },
          )}
        >
          <ListingLandingSection
            listingId={title?.content || "661a99dc-b1a3-4d15-b137-a09fec33faa7"}
            additionalContent={additionalContent}
          />
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionListingComponent.defaultProps = {
  className: null,
  listings: [],
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
  numCols: 3,
  viewAllCategory: null,
  isSquare: true,
  additionalContent: null,
};

SectionListingComponent.propTypes = {
  sectionId: string.isRequired,
  listings: array,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
  viewAllCategory: string,
  isSquare: bool,
  additionalContent: node,

  location: shape({
    search: string.isRequired,
  }).isRequired,

  numCols: number,
};

const SectionListings = compose(
  withRouter,
)(SectionListingComponent);

export default SectionListings;
