import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import Badge from '@mui/material/Badge';
import { ShoppingCartOutlined, PermIdentityIcon } from '@mui/icons-material';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import NavPopover from './NavPopover';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    showSearchForm,
    cartData,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const isStore = currentUser?.attributes?.profile?.publicData?.isStore || false;

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = showSearchForm ? (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
      isInHeader
    />
  ) : <div className={css.searchLink} />;

  const logo = (
    <div className={css.logoWrapper}>
      <LinkedLogo
        className={css.logoLink}
        logoClassName={css.logoImgWrapper}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
    </div>
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const ordersLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="OrdersPage"
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.orders" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="OrdersPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('OrdersPage'))}
            name="OrdersPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountOrdersLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const profileMenuStore = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="OrdersPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('OrdersPage'))}
            name="OrdersPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountOrdersLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="StoreOrdersPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('StoreOrdersPage'))}
            name="StoreOrdersPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountStoreOrdersLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const num_cart_items = cartData?.items?.length || 0;
  const cartLink = (
    <NamedLink name="CartListingPage" className={css.cartLink}>
      <span className={css.cart}>
        <Badge badgeContent={num_cart_items} color="primary">
          <ShoppingCartOutlined />
        </Badge>
      </span>
    </NamedLink>
  );

  const postListingLink = authenticatedOnClientSide && isStore ? (
    <NamedLink className={css.createListingLink} name="NewListingPage">
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </NamedLink>
  ) : <span className={css.createListingLinkEmpty} />;

  const doSearch = (query) => onSearchSubmit({ category: query });

  return (
    <div>
      <nav className={classes}>
        <LinkedLogo
          className={css.logoLink}
          logoClassName={isAuthenticated ? css.hiddenLogoAuthenticated : css.hiddenLogo}
          layout="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        />
        {logo}
        {search}
        {/*postListingLink*/}
        {/*inboxLink*/}
        {/*ordersLink*/}
        {isStore ? profileMenuStore : profileMenu}
        {signupLink}
        {loginLink}
        {cartLink}
      </nav>
      <ul className={css.subnav}>
        <li className={css.subnav_item}>
          <NamedLink name="CategoryPage" params={{ pub_category: 'houseplants' }}>
            Houseplants
          </NamedLink>
        </li>
        <li className={css.subnav_item}>
          <NavPopover type="plants" />
          {/*
            <NamedLink name="CategoryPage" params={{ pub_category: 'plants' }}>
              Plants
            </NamedLink>
          */}
        </li>
        {/*
        <li className={css.subnav_item}>
          <NamedLink name="CategoryPage" params={{ pub_category: 'fertilizer-soil' }}>
            Fertilizer &amp; Soil
          </NamedLink>
        </li>
        */}
        <li className={css.subnav_item}>
          <NavPopover type="pots-planters" />
          {/*
          <NamedLink name="CategoryPage" params={{ pub_category: 'pots-planters' }}>
            Pots &amp; Planters
          </NamedLink>
          */}
        </li>
        <li className={css.subnav_item}>
          <NavPopover type="tools-accessories" />
          {/*
          <NamedLink name="CategoryPage" params={{ pub_category: 'tools-accessories' }}>
            Tools &amp; Accessories
          </NamedLink>
          */}
        </li>
        <li className={css.subnav_item}>
          <NavPopover type="seeds" />
          {/*
          <NamedLink name="CategoryPage" params={{ pub_category: 'seeds' }}>
            Seeds
          </NamedLink>
          */}
        </li>
        <li className={css.subnav_item}>
          <NamedLink name="CategoryPage" params={{ pub_category: 'garden-gifts' }}>
            Garden Gifts
          </NamedLink>
        </li>
        <li className={css.subnav_item}>
          <NavPopover type="experience" />
          {/*
          <NamedLink name="CategoryPage" params={{ pub_category: 'sustainability-shops' }}>
            Sustainability Shops
          </NamedLink>
          */}
        </li>
      </ul>
    </div>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
  showSearchForm: true,
  cartData: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
  showSearchForm: bool,
  cartData: object,
};

export default TopbarDesktop;
