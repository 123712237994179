import React from 'react';
import { func, node, object, shape, string, bool } from 'prop-types';
import classNames from 'classnames';

import Field from '../../Field';

import css from './SectionContainer.module.css';

// This component can be used to wrap some common styles and features of Section-level components.
// E.g: const SectionHero = props => (<SectionContainer><H1>Hello World!</H1></SectionContainer>);
const SectionContainer = props => {
  const {
    className, 
    rootClassName,
    contentClassName,
    id,
    as,
    children,
    appearance,
    options,
    noPadding,
    ...otherProps
  } = props;
  const Tag = as || 'section';
  const classes = classNames(rootClassName || css.root, className);
  const content_classes = classNames(
    contentClassName,
    contentClassName || noPadding ? css.sectionContentNoPadding : css.sectionContent,
    `${id}`.indexOf('section') < 0 ? css[`${id}-section`] : null
  );

  return (
    <Tag className={classes} id={id} {...otherProps}>
      {appearance?.fieldType === 'customAppearance' ? (
        <Field
          data={{ alt: `Background image for ${id}`, ...appearance }}
          className={className}
          options={options}
        />
      ) : null}

      <div className={content_classes}>{children}</div>
    </Tag>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionContainer.defaultProps = {
  rootClassName: null,
  className: null,
  as: 'section',
  children: null,
  appearance: null,
  noPadding: false,
};

SectionContainer.propTypes = {
  rootClassName: string,
  className: string,
  as: string,
  children: node,
  appearance: object,
  options: propTypeOption,
  noPadding: bool,
};

export default SectionContainer;
