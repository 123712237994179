import React, { useState } from 'react';
import { bool, func, node, object, shape, string, array, number } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
/*
import Field, { hasDataInFields } from '../../Field';
*/
import {
  ButtonTabNavHorizontal,
  Heading,
  H2,
  H3,
  H4,
} from '../../../../components';

import SectionContainer from '../SectionContainer';
import ReencleContentWhat from './ReencleContentWhat';
import ReencleContentHow from './ReencleContentHow';
import ReencleContentSpecs from './ReencleContentSpecs';
import ReencleContentFAQ from './ReencleContentFAQ';
import ReencleContentUsing from './ReencleContentUsing';
import ReencleContentNews from './ReencleContentNews';
import ReencleContentEasy from './ReencleContentEasy';
import css from './SectionReencleComposter.module.css';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionReencleComposterComponent = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    // description,
    appearance,
    // callToAction,
    options,
    listings = [],
    // numCols = 3,
    // viewAllCategory,
    // isSquare,
    layoutType = 'stacked',
  } = props;

  const [tab, setTab] = useState('what');

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const isForFeatured =  Array.isArray(listings) && listings.length <= 2;

  // const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const classes = isForFeatured ? classNames(rootClassName || css.root, className, css.blankBG)
    : classNames(rootClassName || css.root, className);

  const offer_content = (
    <>
      <div className={css.tabSection}>
        <div className={css.offerContent}><img src="/static/reencle/offer_nwkg_dsktp_2.jpg" className={css.responsiveImage} alt="Exclusive Offer" /></div>
      </div>
    </>
  );

  const banner_content = (
    <div className={css.greenBanner}>
      <H3>220,000+ Happy Customers!</H3>
    </div>
  );

  const what_content = (
    <>
      <div className={css.tabSection}>
        <ReencleContentWhat sectionId="What" />
      </div>
    </>
  )

  const how_content = (
    <>
      <div className={css.tabSection}>
        <ReencleContentHow sectionId="How" />
      </div>
    </>
  )

  const box_content = (
    <>
      <div
        className={css.tabSection}
      >
        <H2 className={classNames(css.tabSectionTitle, css.noMarginBottom)}>What's in the box?</H2>
        <p style={{ textAlign: 'center', marginTop: 0 }}>
          Reencle is an all-in-one composting solution that comes fully equipped.
        </p>
        <div className={css.layout45_55}>
          <div className={css.layout45_55Item}>
            <img src="/static/reencle/witb_cart_image.png" className={css.responsiveImage} alt="What's in the box'?" />
          </div>
          <div className={css.layout45_55Item}>
            <H4 className={css.greenText}>The package includes:</H4>
            <div className={css.boxContent}>
              <p>Everything you need to kickstart your composting journey!</p>
              <div>
                <KeyboardArrowRightIcon /> Reencle Composter x1
              </div>
              <div>
                <KeyboardArrowRightIcon /> Carbon Filters x2 (lasts 9-12 months)
              </div>
              <div>
                <KeyboardArrowRightIcon /> Compost Starter x1
              </div>
              <div>
                <KeyboardArrowRightIcon /> Handy Shovel x1
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const specs_content = (
    <>
      <div className={css.tabSection}>
        <ReencleContentSpecs sectionId="Specs" />
      </div>
    </>
  );

  const faq_content = (
    <>
      <div className={css.tabSection}>
        <ReencleContentFAQ sectionId="FAQ" />
      </div>
    </>
  );

  const using_content = (
    <>
      <div className={css.tabSection}>
        <ReencleContentUsing sectionId="Using" />
      </div>
    </>
  );

  const news_content = (
    <>
      <div className={css.tabSection}>
        <ReencleContentNews sectionId="News" />
      </div>
    </>
  );

  const easy_content = (
    <>
      <div className={classNames(css.tabSection, css.salmonBackground)}>
        <ReencleContentEasy sectionId="Easy" />
      </div>
    </>
  );

  const footer_content = (
    <>
      <div
        className={css.tabSection}
      >
        <H2 className={classNames(css.tabSectionTitle, css.noMarginTop)}>Take Composting To The Next Level</H2>
        <div className={css.footerBtnContainer}>
          <a href="#featured-listing" className={css.buttonDefault}>ORDER NOW</a>
        </div>
        <div className={css.footerImgContainer}>
          <img src="/static/reencle/bottom.img.png" className={css.responsiveImage} alt="Take Composting To The Next Level" />
        </div>
      </div>
    </>
  );

  const tabContent = (selected_tab) => {
    if (selected_tab === 'offer') {
      return offer_content;
    }
    if (selected_tab === 'what') {
      return what_content;
    }
    if (selected_tab === 'how') {
      return how_content;
    }
    if (selected_tab === 'box') {
      return box_content;
    }
    if (selected_tab === 'specs') {
      return specs_content;
    }
    if (selected_tab === 'faq') {
      return faq_content;
    }
    if (selected_tab === 'using') {
      return using_content;
    }
    if (selected_tab === 'news') {
      return news_content;
    }
    if (selected_tab === 'banner') {
      return banner_content;
    }
    if (selected_tab === 'easy') {
      return easy_content;
    }
    if (selected_tab === 'footer') {
      return footer_content;
    }
    return what_content;
  };

  const tabs = [
    {
      text: (
        <Heading as={'h2'} rootClassName={css.tab}>
          What can I compost?
        </Heading>
      ),
      selected: tab === 'what',
      onClick: () => setTab('what'),
    },
    {
      text: (
        <Heading as={'h2'} rootClassName={css.tab}>
          How to use Reencle
        </Heading>
      ),
      selected: tab === 'how',
      onClick: () => setTab('how'),
    },
    {
      text: (
        <Heading as={'h2'} rootClassName={css.tab}>
          What's in the box?
        </Heading>
      ),
      selected: tab === 'box',
      onClick: () => setTab('box'),
    },
    {
      text: (
        <Heading as={'h2'} rootClassName={css.tab}>
          Tech Specs
        </Heading>
      ),
      selected: tab === 'specs',
      onClick: () => setTab('specs'),
    },
    {
      text: (
        <Heading as={'h2'} rootClassName={css.tab}>
          FAQs
        </Heading>
      ),
      selected: tab === 'faq',
      onClick: () => setTab('faq'),
    },
  ];

  const tabbed_layout = (
    <>
      <div className={css.tabContainer}>
          <ButtonTabNavHorizontal tabs={tabs} skin="traditional" isLong invertColor />
      </div>
      <div className={css.tabContentWrapper}>
        {tabContent(tab)}
      </div>
    </>
  );

  const stacked_layout = (
    <>
      {/*<div className={css.stackedSection}>{tabContent('offer')}</div>*/}
      <div className={css.stackedSection}>{tabContent('banner')}</div>
      <div className={css.stackedSection}>{tabContent('what')}</div>
      <div className={css.stackedSection}>{tabContent('how')}</div>
      <div className={css.stackedSection}>{tabContent('box')}</div>
      <div className={css.stackedSection}>{tabContent('specs')}</div>
      <div className={css.stackedSection}>{tabContent('faq')}</div>
      <div className={css.stackedSection}>{tabContent('news')}</div>
      <div className={css.stackedSection}>{tabContent('easy')}</div>
      <div className={css.stackedSection}>{tabContent('using')}</div>
      <div className={classNames(css.stackedSection, css.salmonBG)}>{tabContent('footer')}</div>
    </>
  )

  return (
    <SectionContainer
      id={sectionId}
      className={classes}
      rootClassName={classNames(rootClassName || css.root)}
      contentClassName={css.sectionContent}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.contentWrapper}>
        {/*
        hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          </header>
        ) : null
        */}
        <div
          className={classNames(
            css.blockContainer,
            defaultClasses.blockContainer,
            // getColumnCSS(numColumns),
            {
              [css.noSidePaddings]: true,
            },
          )}
        >
          {layoutType === 'stacked' ? stacked_layout : tabbed_layout}
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionReencleComposterComponent.defaultProps = {
  className: null,
  listings: [],
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
  numCols: 3,
  viewAllCategory: null,
  isSquare: true,
  layoutType: 'stacked',
};

SectionReencleComposterComponent.propTypes = {
  sectionId: string.isRequired,
  listings: array,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
  viewAllCategory: string,
  isSquare: bool,
  layoutType: string,

  location: shape({
    search: string.isRequired,
  }).isRequired,

  numCols: number,
};

const SectionReencleComposter = compose(
  withRouter,
)(SectionReencleComposterComponent);

export default SectionReencleComposter;
