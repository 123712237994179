import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
} from '../../util/types';
import {
  formatMoney,
  getMaxMinFromVariants,
} from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import {
  userDisplayNameAsString,
  shippingTableAppliesToAuthor,
} from '../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import {
  ModalInMobile,
  PrimaryButton,
  AvatarSmall,
  H1,
  H2,
  Button,
} from '../../components';

const { Money } = sdkTypes;

import css from './OrderPanel.module.css';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price: base_price,
    price_original: price_original_raw,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
    selectedVariant,
    lineItems,
    isForLandingPage = false,
    author,
  } = props;
  const {
    listingType,
    unitType,
    variants: variants_json,
    shippingPriceInSubunitsOneItem,
    shippingPriceVisible,
  } = publicData || {};
  const shippingTableApplies = shippingTableAppliesToAuthor(author?.id?.uuid);
  // console.log('base_price', base_price.amount);
  // console.log('shippingPriceInSubunitsOneItem', shippingPriceInSubunitsOneItem);
  // console.log('price_original_raw', price_original_raw);

  let price = new Money(parseInt(base_price?.amount || 0, 10), base_price?.currency || 'USD');
  let price_original = new Money(parseInt(price_original_raw || 0, 10), 'USD');
  if (!lineItems && !selectedVariant) { // default state without quantity chosen or variant selected
    const shipping_price = (shippingPriceVisible || shippingTableApplies) ? 0 : parseInt(shippingPriceInSubunitsOneItem || 0, 10);
    price = new Money(parseInt(base_price?.amount || 0, 10) + shipping_price, base_price?.currency || 'USD');
    price_original = new Money(parseInt(price_original_raw || 0, 10) + shipping_price, 'USD');
  }

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !base_price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  let { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);

  const product_variants = variants_json ? JSON.parse(variants_json) : null;
  const {
    formattedMaxPrice,
    maxPriceTitle,
    formattedMinPrice,
    minPriceTitle,
    max_price,
    min_price,
  } = getMaxMinFromVariants(product_variants, marketplaceCurrency, intl, selectedVariant);
  if (max_price) {
    formattedPrice = formattedMaxPrice;
    priceTitle = maxPriceTitle;
  }
  if (min_price && min_price !== max_price) {
    formattedPrice = `${formattedMinPrice} - ${formattedMaxPrice}`;
    priceTitle = `${minPriceTitle} - ${maxPriceTitle}`;
  }

  const {
    formattedPrice: formattedOriginalPrice,
    priceTitle: originalPriceTitle,
  } = priceData(price_original, marketplaceCurrency, intl);
  const showOriginalPrice = (
    !!price_original_raw
    && parseInt(price_original_raw || 0, 10) !== parseInt(base_price?.amount || 0, 10)
  );
  const price_classes = classNames(css.price, isForLandingPage ? css.priceLargeMobile : null);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div id="price-container-cta" className={css.priceContainerInCTA}>
      <div id="price-price" className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {
        showOriginalPrice
        && (
          <div id="price-original-price" className={css.originalPriceValue} title={originalPriceTitle}>
            {formattedOriginalPrice}
          </div>
        )
      }
      <div id="price-per-unit-cta" className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div id="price-container" className={css.priceContainer}>
      <p id="price-price" className={price_classes} title={priceTitle}>
        {formattedPrice}
      </p>
      {
        showOriginalPrice
        && (
          <p id="price-original-price" className={css.originalPrice}>{formatMoney(intl, price_original)}</p>
        )
      }
      <div id="price-per-unit" className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onAddToCart = () => {console.log('nope')},
    setVariant,
    selectedVariant,
    showInlineForMobile,
    additionalContent,
    isForLandingPage,
  } = props;

  const publicData = listing?.attributes?.publicData || {};
  const { unitType, transactionProcessAlias = '', shippingPriceVisible } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  let price = listing?.attributes?.price;
  const price_original = publicData?.price_original;
  const [statePrice, setStatePrice] = useState(price);
  const [stateOriginalPrice, setStateOriginalPrice] = useState(price_original);
  const [stateStock, setStateStock] = useState(listing?.currentStock?.attributes?.quantity);

  let listingCartNotes = listing?.attributes?.publicData?.cart_notes;
  if (selectedVariant?.cart_notes) {
    listingCartNotes = selectedVariant.cart_notes;
  }
  const listingShippingDisclaimer = listing?.attributes?.publicData?.shipping_disclaimer;

  useEffect(() => {
    // const shipping_price = parseInt(publicData?.shippingPriceInSubunitsOneItem || 0, 10);
    setStatePrice(new Money(price?.amount, marketplaceCurrency));
    setStateOriginalPrice(price_original);
    setStateStock(listing?.currentStock?.attributes?.quantity);
  }, [listing?.id?.uuid])

  const getItemPriceFromLineItems = (lineItemArray, get_original_price) => {
    if (!Array.isArray(lineItemArray)) return 0;
    let shipping_fee_per_item = 0;
    let updated_item_price = 0;
    let shippingTableApplies = false;
    const shipping_line_item = lineItemArray.find(fi => fi.code === 'line-item/shipping-fee');
    if (shipping_line_item) {
      shipping_fee_per_item = shipping_line_item.unitPrice?.amount || 0;
      shippingTableApplies = shipping_line_item.shipping_table_applies;
    }
    const item_line_item = lineItemArray.find(fi => fi.code === 'line-item/item');
    if (item_line_item) {
      const line_item_quantity = parseInt(item_line_item.quantity.toString(), 10);
      const line_total_amount = get_original_price ? (item_line_item.originalPrice || 0) * line_item_quantity : item_line_item.lineTotal?.amount || 0;
      updated_item_price = (shippingPriceVisible || shippingTableApplies) ? line_total_amount / line_item_quantity
        : (line_total_amount + shipping_fee_per_item) / line_item_quantity;
      // console.log('shippingPriceVisible', shippingPriceVisible, line_total_amount, shipping_fee_per_item, updated_item_price);
    }
    return updated_item_price;
  }

  /*
  const getItemHiddenShippingPriceFromLineItems = (lineItemArray) => {
    if (!Array.isArray(lineItemArray)) return 0;
    let shipping_fee_per_item = 0;
    let updated_item_price = 0;
    const shipping_line_item = lineItemArray.find(fi => fi.code === 'line-item/shipping-fee');
    if (shipping_line_item) {
      shipping_fee_per_item = shipping_line_item.unitPrice?.amount || 0;
    }
    const item_line_item = lineItemArray.find(fi => fi.code === 'line-item/item');
    if (item_line_item) {
      const line_item_quantity = parseInt(item_line_item.quantity.toString(), 10);
      updated_item_price = (shipping_fee_per_item) / line_item_quantity;
    }
    return updated_item_price;
  }
  */

  useEffect(() => {
    // console.log('lineItems', getItemPriceFromLineItems(lineItems), statePrice?.amount);
    const new_price = getItemPriceFromLineItems(lineItems);
    const line_item_original_price = getItemPriceFromLineItems(lineItems, true);
    // const new_hidden_shipping_price = getItemHiddenShippingPriceFromLineItems(lineItems);
    // console.log('price_original', price_original, new_hidden_shipping_price);
    // console.log('lineItems', lineItems);
    // console.log('new_price', new_price, line_item_original_price);
    if (new_price) {
      setStatePrice(new Money(new_price, marketplaceCurrency));
      setStateOriginalPrice(parseInt(line_item_original_price || 0, 10));
    }
  }, [lineItems]);

  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  // const currentStock = listing.currentStock?.attributes?.quantity;
  const currentStock = stateStock;
  // console.log('currentStock', currentStock);
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const handleAddToCart = (e) => {
    e.preventDefault();
    const shipping_line_item = Array.isArray(lineItems) ? lineItems.find(li => li.code === 'line-item/shipping-fee') : null;
    const {
      unitPrice: shipping_fee_obj,
    } = shipping_line_item || {};
    const shipping_price = shipping_fee_obj?.amount || 0;
    onAddToCart({
      items: [
        {
          id: listing?.id?.uuid,
          quantity: 1,
          price: parseFloat(price?.amount || 0),
          shipping_price: parseFloat(shipping_price || 0),
          // deliveryMethod,
        },
      ],
    });
    toast.success('Your cart has been updated.');
  }

  const listingHasMultipleShippingFeeDiscount = parseInt(publicData?.shippingPriceInSubunitsOneItem || 0, 10) > parseInt(publicData?.shippingPriceInSubunitsAdditionalItems || 0, 10);

  return (
    <div id="order-panel-container" className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
        showInlineForMobile={showInlineForMobile}
      >
        {
          !showInlineForMobile
          && (
            <>
              <div id="header-info-container" className={showInlineForMobile ? css.modalHeadingInline : css.modalHeading}>
                <div id="header-info-author" className={css.mobileAuthor}>
                  {/* <AvatarSmall user={author} className={css.providerAvatar} /> */}
                  <span className={css.providerNameLinked}>
                    <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
                  </span>
                  <span className={css.providerNamePlain}>
                    <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
                  </span>
                </div>
                <H1 className={css.heading}>{title}</H1>
              </div>

              <div id="header-info-author-name" className="css.desktopHeading">
                <span className={css.providerNameLinked}>
                  <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
                </span>
              </div>

              <div id="order-heading" className={css.orderHeading}>
                {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
                {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
              </div>
            </>
          )
        }

        <PriceMaybe
          price={statePrice}
          price_original={stateOriginalPrice}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          selectedVariant={selectedVariant}
          lineItems={lineItems}
          isForLandingPage={isForLandingPage}
          author={author}
        />

        {showPriceMissing ? (
          <PriceMissing />
        ) : showInvalidCurrency ? (
          <InvalidCurrency />
        ) : showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : showProductOrderForm ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            currentStock={currentStock}
            pickupEnabled={pickupEnabled}
            shippingEnabled={shippingEnabled}
            listingId={listing.id}
            listingName={title}
            listingTitle={listing?.attributes?.title}
            listingBrand={authorDisplayName}
            listingCartNotes={listingCartNotes}
            listingShippingDisclaimer={listingShippingDisclaimer}
            listingHasMultipleShippingFeeDiscount={listingHasMultipleShippingFeeDiscount}
            listingShippingPriceVisible={shippingPriceVisible}
            variants={listing.attributes.publicData.variants}
            variant_stocks={listing.attributes.publicData.variant_stocks}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            onAddToCart={onAddToCart}
            setVariant={setVariant}
            selectedVariant={selectedVariant}
            setStatePrice={setStatePrice}
            setStateOriginalPrice={setStateOriginalPrice}
            setStateStock={setStateStock}
            showInlineForMobile={showInlineForMobile}
            additionalContent={additionalContent}
          />
        ) : showInquiryForm ? (
          <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null}
      </ModalInMobile>
      {/*
      <div className={css.openOrderForm}>
        <PriceMaybe
          price={statePrice}
          price_original={stateOriginalPrice}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          showCurrencyMismatch
          selectedVariant={selectedVariant}
          lineItems={lineItems}
        />

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <>
            {
              (
                <PrimaryButton
                  onClick={handleSubmit(
                    isOwnListing,
                    isClosed,
                    showInquiryForm,
                    onSubmit,
                    history,
                    location
                  )}
                  disabled={isOutOfStock}
                >
                  {isBooking ? (
                    <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
                  ) : isOutOfStock ? (
                    <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
                  ) : isPurchase ? (
                    <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
                  ) : (
                    <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
                  )}
                </PrimaryButton>
              )
            }
          </>
        )}
      </div>
      */}
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  setVariant: () => {},
  selectedVariant: null,
  showInlineForMobile: false,
  isForLandingPage: false,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  onAddToCart: func.isRequired,
  setVariant: func,
  selectedVariant: object,

  showInlineForMobile: bool,
  additionalContent: node,
  isForLandingPage: bool,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
