import React, { useEffect } from 'react';
import { bool, func, node, object, shape, string, array, number } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import {
  ListingCard,
  NamedLink,
} from '../../../../components';
import {
  fetchComboListingData,
} from './SectionComboListing.duck';

import SectionContainer from '../SectionContainer';
// import ListingLandingSection from '../../../ListingPage/ListingLandingSection';
import css from './SectionComboListing.module.css';

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 265px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionComboListingComponent = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    listings = [],
    // viewAllCategory,
    // isSquare,
    blocks,
    numColumns,
    onFetchComboListingData,
    combo_listing_data,
    isInsideContainer,
  } = props;

  useEffect(() => {
    onFetchComboListingData(sectionId);
  }, []);

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasBlocks = blocks?.length > 0;

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const classes = classNames(rootClassName || css.root, className);

    const cardRenderSizes = isMapVariant => {
      if (isMapVariant) {
        // Panel width relative to the viewport
        const panelMediumWidth = 50;
        const panelLargeWidth = 62.5;
        return [
          '(max-width: 767px) 100vw',
          `(max-width: 1023px) ${panelMediumWidth}vw`,
          `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
          `${panelLargeWidth / 3}vw`,
        ].join(', ');
      } else {
        // Panel width relative to the viewport
        const panelMediumWidth = 50;
        const panelLargeWidth = 62.5;
        return [
          '(max-width: 549px) 100vw',
          '(max-width: 767px) 50vw',
          `(max-width: 1439px) 26vw`,
          `(max-width: 1920px) 18vw`,
          `14vw`,
        ].join(', ');
      }
    };

    const listings_content = (num_cols = 2) => {
      let listings_to_display = combo_listing_data?.[`${sectionId}`]?.[`${num_cols}-col-listings`] || [];
      if (Array.isArray(listings_to_display) && listings_to_display.length > 0) {
        return (
          <div className={css.rootListingCards}>
            <div className={css[`listingCards-${num_cols}`]}>
              {listings_to_display.map(l => (
                <ListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes(false)}
                  showDescription
                  showViewButton
                  target="_new"
                  isSquare
                  forceSquare
                />
              ))}
            </div>
          </div>
        );
      }
      return null;
    }

  return (
    <SectionContainer
      id={sectionId}
      className={css.sectionContainer}
      rootClassName={classNames(rootClassName || css.root)}
      contentClassName={css.sectionContent}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.contentWrapper}>
        {
        hasHeaderFields ? (
          <header className={classNames(css.sectionDetails, defaultClasses.sectionDetails)}>
            <Field data={title} className={classNames(defaultClasses.title, css.title)} options={fieldOptions} />
            <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          </header>
        ) : null
        }
        <div className={css.contentContainer}>
          {hasBlocks ? (
            <div
              className={classNames(
                defaultClasses.blockContainer,
                getColumnCSS(numColumns),
                css.blockContainer,
                {
                [css.noSidePaddings]: isInsideContainer,
              })}
            >
              <BlockBuilder
                ctaButtonClass={defaultClasses.ctaButton}
                blocks={blocks}
                sectionId={sectionId}
                responsiveImageSizes={getResponsiveImageSizes(numColumns)}
                options={options}
              />
            </div>
          ) : null}
          {listings_content(2)}
          {listings_content(3)}
          {listings_content(4)}
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionComboListingComponent.defaultProps = {
  className: null,
  listings: [],
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
  viewAllCategory: null,
  isSquare: true,
  additionalContent: null,
  combo_listing_data: {},
};

SectionComboListingComponent.propTypes = {
  sectionId: string.isRequired,
  listings: array,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
  viewAllCategory: string,
  isSquare: bool,
  additionalContent: node,

  location: shape({
    search: string.isRequired,
  }).isRequired,

  combo_listing_data: object,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    listings,
  } = state.SectionComboListing;
  return {
    combo_listing_data: listings,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchComboListingData: groupId => dispatch(fetchComboListingData(groupId)),
});

const SectionListings = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
)(SectionComboListingComponent);

export default SectionListings;
