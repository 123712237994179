import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCart } from '../../containers/CartListingPage/CartListingPage.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';

import { queryLandingData } from '../../util/api';

import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'landing-page';

// ================ Action types ================ //

export const CURATED_CAROUSEL_LISTINGS_REQUEST = 'app/LandingPage/CURATED_CAROUSEL_LISTINGS_REQUEST';
export const CURATED_CAROUSEL_LISTINGS_SUCCESS = 'app/LandingPage/CURATED_CAROUSEL_LISTINGS_SUCCESS';
export const CURATED_CAROUSEL_LISTINGS_ERROR = 'app/LandingPage/CURATED_CAROUSEL_LISTINGS_ERROR';

export const CURATED_STACK_LISTINGS_REQUEST = 'app/LandingPage/CURATED_STACK_LISTINGS_REQUEST';
export const CURATED_STACK_LISTINGS_SUCCESS = 'app/LandingPage/CURATED_STACK_LISTINGS_SUCCESS';
export const CURATED_STACK_LISTINGS_ERROR = 'app/LandingPage/CURATED_STACK_LISTINGS_ERROR';

export const FEATURED_LISTINGS_REQUEST = 'app/LandingPage/FEATURED_LISTINGS_REQUEST';
export const FEATURED_LISTINGS_SUCCESS = 'app/LandingPage/FEATURED_LISTINGS_SUCCESS';
export const FEATURED_LISTINGS_ERROR = 'app/LandingPage/FEATURED_LISTINGS_ERROR';

export const LATEST_LISTINGS_REQUEST = 'app/LandingPage/LATEST_LISTINGS_REQUEST';
export const LATEST_LISTINGS_SUCCESS = 'app/LandingPage/LATEST_LISTINGS_SUCCESS';
export const LATEST_LISTINGS_ERROR = 'app/LandingPage/LATEST_LISTINGS_ERROR';

export const PROMOTED_LISTINGS_REQUEST = 'app/LandingPage/PROMOTED_LISTINGS_REQUEST';
export const PROMOTED_LISTINGS_SUCCESS = 'app/LandingPage/PROMOTED_LISTINGS_SUCCESS';
export const PROMOTED_LISTINGS_ERROR = 'app/LandingPage/PROMOTED_LISTINGS_ERROR';

export const SPECIALTY_LISTINGS_REQUEST = 'app/LandingPage/SPECIALTY_LISTINGS_REQUEST';
export const SPECIALTY_LISTINGS_SUCCESS = 'app/LandingPage/SPECIALTY_LISTINGS_SUCCESS';
export const SPECIALTY_LISTINGS_ERROR = 'app/LandingPage/SPECIALTY_LISTINGS_ERROR';

export const POLLINATOR_LISTINGS_REQUEST = 'app/LandingPage/POLLINATOR_LISTINGS_REQUEST';
export const POLLINATOR_LISTINGS_SUCCESS = 'app/LandingPage/POLLINATOR_LISTINGS_SUCCESS';
export const POLLINATOR_LISTINGS_ERROR = 'app/LandingPage/POLLINATOR_LISTINGS_ERROR';

export const SMALL_SPACE_LISTINGS_REQUEST = 'app/LandingPage/SMALL_SPACE_LISTINGS_REQUEST';
export const SMALL_SPACE_LISTINGS_SUCCESS = 'app/LandingPage/SMALL_SPACE_LISTINGS_SUCCESS';
export const SMALL_SPACE_LISTINGS_ERROR = 'app/LandingPage/SMALL_SPACE_LISTINGS_ERROR';

export const UNIQUE_LISTINGS_REQUEST = 'app/LandingPage/UNIQUE_LISTINGS_REQUEST';
export const UNIQUE_LISTINGS_SUCCESS = 'app/LandingPage/UNIQUE_LISTINGS_SUCCESS';
export const UNIQUE_LISTINGS_ERROR = 'app/LandingPage/UNIQUE_LISTINGS_ERROR';

export const ON_SALE_LISTINGS_REQUEST = 'app/LandingPage/ON_SALE_LISTINGS_REQUEST';
export const ON_SALE_LISTINGS_SUCCESS = 'app/LandingPage/ON_SALE_LISTINGS_SUCCESS';
export const ON_SALE_LISTINGS_ERROR = 'app/LandingPage/ON_SALE_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchListingsInProgress: false,
  fetchListingsListingsError: null,
  currentPageResultIds: [],
  latestPageResultIds: [],
  promotedPageResultIds: [],
  curatedCarouselPageResultIds: [],
  curatedStackPageResultIds: [],
  specialtyPageResultIds: [],
  pollinatorPageResultIds: [],
  smallSpacePageResultIds: [],
  uniquePageResultIds: [],
  onSalePageResultIds: [],
};

const resultIds = data => {
  return data.data.map(l => l.id);
};

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CURATED_CAROUSEL_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsListingsError: null,
      };
    case CURATED_CAROUSEL_LISTINGS_SUCCESS:
      return {
        ...state,
        curatedCarouselPageResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case CURATED_CAROUSEL_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsListingsError: payload,
      };

    case CURATED_STACK_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsListingsError: null,
      };
    case CURATED_STACK_LISTINGS_SUCCESS:
      return {
        ...state,
        curatedStackPageResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case CURATED_STACK_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsListingsError: payload,
      };

    case FEATURED_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsListingsError: null,
      };
    case FEATURED_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case FEATURED_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsListingsError: payload,
      };

    case LATEST_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsListingsError: null,
      };
    case LATEST_LISTINGS_SUCCESS:
      return {
        ...state,
        latestPageResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case LATEST_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsListingsError: payload,
      };

    case PROMOTED_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsListingsError: null,
      };
    case PROMOTED_LISTINGS_SUCCESS:
      return {
        ...state,
        promotedPageResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case PROMOTED_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsListingsError: payload,
      };

    case SPECIALTY_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsListingsError: null,
      };
    case SPECIALTY_LISTINGS_SUCCESS:
      return {
        ...state,
        specialtyPageResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case SPECIALTY_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsListingsError: payload,
      };
  
    case POLLINATOR_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsListingsError: null,
      };
    case POLLINATOR_LISTINGS_SUCCESS:
      return {
        ...state,
        pollinatorPageResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case POLLINATOR_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsListingsError: payload,
      };

    case SMALL_SPACE_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsListingsError: null,
      };
    case SMALL_SPACE_LISTINGS_SUCCESS:
      return {
        ...state,
        smallSpacePageResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case SMALL_SPACE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsListingsError: payload,
      };

    case UNIQUE_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsListingsError: null,
      };
    case UNIQUE_LISTINGS_SUCCESS:
      return {
        ...state,
        uniquePageResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case UNIQUE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsListingsError: payload,
      };

    case ON_SALE_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsListingsError: null,
      };
    case ON_SALE_LISTINGS_SUCCESS:
      return {
        ...state,
        onSalePageResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case ON_SALE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsListingsError: payload,
      };

    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const specialtyListingsRequest = searchParams => ({
  type: SPECIALTY_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const specialtyListingsSuccess = response => ({
  type: SPECIALTY_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const specialtyListingsError = e => ({
  type: SPECIALTY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const pollinatorListingsRequest = searchParams => ({
  type: POLLINATOR_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const pollinatorListingsSuccess = response => ({
  type: POLLINATOR_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const pollinatorListingsError = e => ({
  type: POLLINATOR_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const smallSpaceListingsRequest = searchParams => ({
  type: SMALL_SPACE_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const smallSpaceListingsSuccess = response => ({
  type: SMALL_SPACE_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const smallSpaceListingsError = e => ({
  type: SMALL_SPACE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const uniqueListingsRequest = searchParams => ({
  type: UNIQUE_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const uniqueListingsSuccess = response => ({
  type: UNIQUE_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const uniqueListingsError = e => ({
  type: UNIQUE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const onSaleListingsRequest = searchParams => ({
  type: ON_SALE_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const onSaleListingsSuccess = response => ({
  type: ON_SALE_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const onSaleListingsError = e => ({
  type: ON_SALE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const curatedCarouselListingsRequest = searchParams => ({
  type: CURATED_CAROUSEL_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const curatedCarouselListingsSuccess = response => ({
  type: CURATED_CAROUSEL_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const curatedCarouselListingsError = e => ({
  type: CURATED_CAROUSEL_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const curatedStackListingsRequest = searchParams => ({
  type: CURATED_STACK_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const curatedStackListingsSuccess = response => ({
  type: CURATED_STACK_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const curatedStackListingsError = e => ({
  type: CURATED_STACK_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const featuredListingsRequest = searchParams => ({
  type: FEATURED_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const featuredListingsSuccess = response => ({
  type: FEATURED_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const featuredListingsError = e => ({
  type: FEATURED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const latestListingsRequest = searchParams => ({
  type: LATEST_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const latestListingsSuccess = response => ({
  type: LATEST_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const latestListingsError = e => ({
  type: LATEST_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const promotedListingsRequest = searchParams => ({
  type: PROMOTED_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const promotedListingsSuccess = response => ({
  type: PROMOTED_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const promotedListingsError = e => ({
  type: PROMOTED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// SearchPage can enforce listing query to only those listings with valid listingType
// NOTE: this only works if you have set 'enum' type search schema to listing's public data fields
//       - listingType
//       Same setup could be expanded to 2 other extended data fields:
//       - transactionProcessAlias
//       - unitType
//       ...and then turned enforceValidListingType config to true in configListing.js
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
const searchValidListingTypes = (config) => {
  const listingTypes = config.listing.listingTypes;
  return config.listing.enforceValidListingType
    ? {
        pub_listingType: listingTypes.map(l => l.listingType),
        // pub_transactionProcessAlias: listingTypes.map(l => l.transactionType.alias),
        // pub_unitType: listingTypes.map(l => l.transactionType.unitType),
      }
    : {};
};

const priceSearchParams = priceParam => {
  const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
  const values = priceParam ? priceParam.split(',') : [];
  return priceParam && values.length === 2
    ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
    : {};
};

const datesSearchParams = (datesParam, config) => {
  const searchTZ = 'Etc/UTC';
  const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
  const values = datesParam ? datesParam.split(',') : [];
  const hasValues = datesFilter && datesParam && values.length === 2;
  const { dateRangeMode, availability } = datesFilter || {};
  const isNightlyMode = dateRangeMode === 'night';
  const isEntireRangeAvailable = availability === 'time-full';

  // SearchPage need to use a single time zone but listings can have different time zones
  // We need to expand/prolong the time window (start & end) to cover other time zones too.
  //
  // NOTE: you might want to consider changing UI so that
  //   1) location is always asked first before date range
  //   2) use some 3rd party service to convert location to time zone (IANA tz name)
  //   3) Make exact dates filtering against that specific time zone
  //   This setup would be better for dates filter,
  //   but it enforces a UX where location is always asked first and therefore configurability
  const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
  const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

  const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
  const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
  const endDate =
    hasValues && isNightlyMode
      ? endRaw
      : hasValues
      ? getExclusiveEndDate(endRaw, searchTZ)
      : null;

  const today = getStartOf(new Date(), 'day', searchTZ);
  const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
  const hasValidDates =
    hasValues &&
    startDate.getTime() >= possibleStartDate.getTime() &&
    startDate.getTime() <= endDate.getTime();

  const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
  const day = 1440;
  const hour = 60;
  // When entire range is required to be available, we count minutes of included date range,
  // but there's a need to subtract one hour due to possibility of daylight saving time.
  // If partial range is needed, then we just make sure that the shortest time unit supported
  // is available within the range.
  // You might want to customize this to match with your time units (e.g. day: 1440 - 60)
  const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
  return hasValidDates
    ? {
        start: getProlongedStart(startDate),
        end: getProlongedEnd(endDate),
        // Availability can be time-full or time-partial.
        // However, due to prolonged time window, we need to use time-partial.
        availability: 'time-partial',
        // minDuration uses minutes
        minDuration,
      }
    : {};
};

const stockFilters = datesMaybe => {
  const hasDatesFilterInUse = Object.keys(datesMaybe).length > 0;

  // If dates filter is not in use,
  //   1) Add minStock filter with default value (1)
  //   2) Add relaxed stockMode: "match-undefined"
  // The latter is used to filter out all the listings that explicitly are out of stock,
  // but keeps bookable and inquiry listings.
  return hasDatesFilterInUse ? {} : { minStock: 1, stockMode: 'match-undefined' };
};

export const fetchSpecialtyListings = (searchParams, config, data) => (dispatch, getState, sdk) => {
  dispatch(specialtyListingsRequest(searchParams));

  if (data) {
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(data, sanitizeConfig));
    dispatch(specialtyListingsSuccess(data));
    return Promise.resolve(data);
  }

  const error = new Error('Could not fetch specialty listings');
  dispatch(specialtyListingsError(error));
  return Promise.resolve();
};

export const fetchPollinatorListings = (searchParams, config, data) => (dispatch, getState, sdk) => {
  dispatch(pollinatorListingsRequest(searchParams));

  if (data) {
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(data, sanitizeConfig));
    dispatch(pollinatorListingsSuccess(data));
    return Promise.resolve(data);
  }

  const error = new Error('Could not fetch pollinator listings');
  dispatch(pollinatorListingsError(error));
  return Promise.resolve();
};

export const fetchSmallSpaceListings = (searchParams, config, data) => (dispatch, getState, sdk) => {
  dispatch(smallSpaceListingsRequest(searchParams));

  if (data) {
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(data, sanitizeConfig));
    dispatch(smallSpaceListingsSuccess(data));
    return Promise.resolve(data);
  }

  const error = new Error('Could not fetch small space listings');
  dispatch(smallSpaceListingsError(error));
  return Promise.resolve();
};

export const fetchUniqueListings = (searchParams, config, data) => (dispatch, getState, sdk) => {
  dispatch(uniqueListingsRequest(searchParams));

  if (data) {
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(data, sanitizeConfig));
    dispatch(uniqueListingsSuccess(data));
    return Promise.resolve(data);
  }

  const error = new Error('Could not fetch unique listings');
  dispatch(uniqueListingsError(error));
  return Promise.resolve();
};

export const fetchOnSaleListings = (searchParams, config, data) => (dispatch, getState, sdk) => {
  dispatch(onSaleListingsRequest(searchParams));

  if (data) {
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(data, sanitizeConfig));
    dispatch(onSaleListingsSuccess(data));
    return Promise.resolve(data);
  }

  const error = new Error('Could not fetch on sale listings');
  dispatch(onSaleListingsError(error));
  return Promise.resolve();
};

export const fetchCuratedCarouselListings = (searchParams, config, data) => (dispatch, getState, sdk) => {
  dispatch(curatedCarouselListingsRequest(searchParams));

  if (data) {
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(data, sanitizeConfig));
    dispatch(curatedCarouselListingsSuccess(data));
    return Promise.resolve(data);
  }

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates, config);
  const stockMaybe = stockFilters(datesMaybe);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...stockMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config),
    perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(curatedCarouselListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(curatedCarouselListingsError(storableError(e)));
      throw e;
    });
};

export const fetchCuratedStackListings = (searchParams, config, data) => (dispatch, getState, sdk) => {
  dispatch(curatedStackListingsRequest(searchParams));

  if (data) {
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(data, sanitizeConfig));
    dispatch(curatedStackListingsSuccess(data));
    return Promise.resolve(data);
  }

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates, config);
  const stockMaybe = stockFilters(datesMaybe);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...stockMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config),
    perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(curatedStackListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(curatedStackListingsError(storableError(e)));
      throw e;
    });
};


export const fetchFeaturedListings = (searchParams, config, data) => (dispatch, getState, sdk) => {
  dispatch(featuredListingsRequest(searchParams));

  if (data) {
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(data, sanitizeConfig));
    dispatch(featuredListingsSuccess(data));
    return Promise.resolve(data);
  }

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates, config);
  const stockMaybe = stockFilters(datesMaybe);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...stockMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config),
    perPage,
  };

  // console.log('params', params);

  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(featuredListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(featuredListingsError(storableError(e)));
      throw e;
    });
};

export const fetchLatestListings = (searchParams, config, data) => (dispatch, getState, sdk) => {
  dispatch(latestListingsRequest(searchParams));

  if (data) {
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(data, sanitizeConfig));
    dispatch(latestListingsSuccess(data));
    return Promise.resolve(data);
  }

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates, config);
  const stockMaybe = stockFilters(datesMaybe);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...stockMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config),
    perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(latestListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(latestListingsError(storableError(e)));
      throw e;
    });
};

export const fetchPromotedListings = (searchParams, config, data) => (dispatch, getState, sdk) => {
  dispatch(promotedListingsRequest(searchParams));

  if (data) {
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(data, sanitizeConfig));
    dispatch(promotedListingsSuccess(data));
    return Promise.resolve(data);
  }

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates, config);
  const stockMaybe = stockFilters(datesMaybe);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...stockMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config),
    perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(promotedListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(promotedListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  return queryLandingData()
    .then((landing_data) => {
      const {
        featured_listings = {},
        curated_carousel_listings = {},
        curated_stacked_listings = {},
        latest_listings = {},
        specialty_listings = {},
        pollinator_listings = {},
        small_space_listings = {},
        unique_listings = {},
        on_sale_listings = {},
      } = landing_data || {};
      return dispatch(fetchFeaturedListings(
        {},
        config,
        featured_listings,
      ))
      .then(() => dispatch(fetchCuratedCarouselListings(
        {},
        config,
        curated_carousel_listings,
      )))
      .then(() => dispatch(fetchCuratedStackListings(
        {},
        config,
        curated_stacked_listings,
      )))
      .then(() => dispatch(fetchLatestListings(
        {},
        config,
        latest_listings,
      )))
      .then(() => dispatch(fetchSpecialtyListings(
        {},
        config,
        specialty_listings,
      )))
      .then(() => dispatch(fetchPollinatorListings(
        {},
        config,
        pollinator_listings,
      )))
      .then(() => dispatch(fetchSmallSpaceListings(
        {},
        config,
        small_space_listings,
      )))
      .then(() => dispatch(fetchUniqueListings(
        {},
        config,
        unique_listings,
      )))
      .then(() => dispatch(fetchOnSaleListings(
        {},
        config,
        on_sale_listings,
      )))
      .then(() => dispatch(fetchCart(config)))
      .then(() => dispatch(fetchPageAssets(pageAsset, true)));
    });
};
