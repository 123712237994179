import React, { useState, useEffect } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  // LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  // SCHEMA_TYPE_MULTI_ENUM,
  // SCHEMA_TYPE_TEXT,
  propTypes,
  // filterIsVisible,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import {
  ensureListing,
  // ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText, possessive } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { showListing } from './ListingPage.duck';

import {
  H4,
  NamedLink,
  // NamedRedirect,
  OrderPanel,
  IconSpinner,
} from '../../components';

import {
  // sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  // doSubmitListingReview,
  // doToggleListingReview,
} from './ListingPage.duck';

import {
  addToCart,
} from '../CartListingPage/CartListingPage.duck';

import {
  // priceData,
  handleContactUser,
  // handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
// import SectionTextMaybe from './SectionTextMaybe';
// import SectionFormattedTextMaybe from './SectionFormattedTextMaybe';
// import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
// import SectionReviews from './SectionReviews';
// import SectionAuthorMaybe from './SectionAuthorMaybe';
// import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};
const delay = ms => new Promise(resolve => window.setTimeout(resolve, ms));

export const ListingPageComponent = props => {
  // const [inquiryModalOpen, setInquiryModalOpen] = useState(
  //   props.inquiryModalOpenForListingId === props.listingId
  // );
  const [currentListing, setCurrentListing] = useState(null);
  const [selectedVariant, setVariant] = useState(null);

  const {
    isAuthenticated,
    currentUser,
    getListing,
    // getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams = {},
    listingId,
    location,
    // scrollingDisabled,
    showListingError,
    // reviews,
    // fetchReviewsError,
    // sendInquiryInProgress,
    // sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    // onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    onAddToCart,
    onSubmitListingReview,
    // submitListingReviewInProgress,
    // submitListingReviewError,
    onToggleListingReview,
    // toggleListingReviewInProgress,
    // toggleListingReviewError,
    onShowListing,
    entities,
    additionalContent,
  } = props;

  useEffect(() => {
    if (window && window.gtag && currentListing?.id) {
      window.gtag('event', 'view_item', {
        currency: 'USD',
        value: parseFloat(price?.amount || 0) / 100,
        items: [
          {
            item_id: currentListing?.id.uuid,
            item_name: currentListing?.attributes?.title,
            item_brand: authorDisplayName,
            price: (parseFloat(price?.amount || 0) / 100) + (parseFloat(shippingPriceInSubunitsOneItem || 0) / 100),
            quantity: 1,
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    if (listingId) {
      // console.log('retrieve listing', listingId, config);
      const listingUUID = new UUID(listingId);
      onShowListing(listingUUID, config)
        .catch((err) => {
          console.error('error retrieving listing', err.message);
        });
    }
  }, [listingId]);

  useEffect(() => {
    if (listingId) {
      const listingUUID = new UUID(listingId);
      const listing = getListing(listingUUID);
      const ensured_listing = ensureListing(listing);
      // console.log('listing', listing, ensured_listing);
      if (ensured_listing) {
        setCurrentListing(ensured_listing);
      }
      // console.log('entities', entities);
    }
  }, [entities]);

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  // const listingConfig = listingConfigProp || config.listing;
  // const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;

  const listingSlug = rawParams.slug || createSlug(currentListing?.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';
 
  /*
  const isApproved =
    currentListing?.id && currentListing?.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.

  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;
  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }
  */

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <div>404</div>;
  } else if (showListingError) {
    // Other error in fetching listing
    return <div>showListingError</div>;
  } else if (!currentListing?.id) {
    // Still loading the listing
    return (
      <div>
        loading...
        <LoadingSpinner />
      </div>
    );
  }

  /*
  if (!rawParams.category && currentListing) {
    return <NamedRedirect
      name="CategoryListingPage"
      params={{
        ...params,
        category: currentListing?.attributes?.publicData?.primary_category,
      }}
      search={location.search}
    />;
  }
  */

  const {
    // description = '',
    // geolocation = null,
    price = null,
    title = '',
    publicData = {},
    // metadata = {},
  } = currentListing?.attributes;

  const {
    shippingPriceInSubunitsOneItem = 0,
    wwli = null,
  } = publicData || {};

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing?.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing?.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing?.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const authorContactEmail = ensuredAuthor?.attributes?.profile?.publicData?.returnEmail || ensuredAuthor?.attributes?.profile?.publicData?.return_email || null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  // const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  /*
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  */
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  // const onSubmitInquiry = handleSubmitInquiry({
  //   ...commonParams,
  //   getListing,
  //   onSendInquiry,
  //   setInquiryModalOpen,
  // });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing?.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));
  // console.log('author', currentAuthor);
  // console.log('params', params);
  const handleSubmitReview = async (values) => {
    if (listingId) {
      const {
        reviewContent: review,
        reviewRating: rating,
      } = values || {};
      onSubmitListingReview({
        review,
        rating,
        listing_id: listingId?.uuid || listingId || null,
        author_id: currentAuthor?.id?.uuid,
      })
      .then(() => {
        toast.success('Your listing has been submitted. Thank you.');
      })
      .catch((err) => {
        toast.error(err.message);
      })
    } else {
      toast.error('No listing provided');
    }
  };
  const handleToggleReview = async (ratingId) => {
    if (ratingId) {
      onToggleListingReview(ratingId, listingId?.uuid || listingId || null)
      .then(() => {
        toast.success('Your listing has been submitted. Thank you.');
      })
      .catch((err) => {
        toast.error(err.message);
      })
    } else {
      toast.error('No review provided');
    }
  };
  return (
    <>
      <div id="content-wrapper-product-layout" className={css.contentWrapperForProductLayoutSection}>
        <div id="main-column-product-layout" className={css.mainColumnForProductLayout}>

          <div id="mobile-heading" className={css.mobileHeading} style={{marginTop: 0}}>
            <>
              <span style={{ paddingRight: 6 }}>Sold by</span>
              <NamedLink
                className={css.authorNameLink}
                name="ProfilePage"
                params={{
                  ...params,
                  id: currentAuthor?.id?.uuid,
                }}
                // to={{ hash: '#author' }}
              >
                {authorDisplayName}
              </NamedLink>
            </>
            <H4 as="h1" className={css.orderPanelTitle}>
              <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
            </H4>
          </div>
          {/*
          currentListing?.id ? (
            <ActionBarMaybe
              className={css.actionBarForProductLayout}
              isOwnListing={isOwnListing}
              listing={currentListing}
              editParams={{
                id: listingId?.uuid || listingId,
                slug: listingSlug,
                type: listingPathParamType,
                tab: listingTab,
              }}
            />
          ) : null
            */}
          <SectionGallery
            listing={currentListing}
            variantPrefix={config.layout.listingImage.variantPrefix}
            selectedVariant={selectedVariant}
          />

          <div id="mobile-order-panel-container" className={css.mobileOrderPanelContainer}>
            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              onSubmit={handleOrderSubmit}
              showInlineForMobile
              authorLink={ 
                <>
                  <span style={{ paddingRight: 6 }}>Sold by</span>
                  <NamedLink
                    className={css.authorNameLink}
                    name="ProfilePage"
                    params={{
                      ...params,
                      id: currentAuthor?.id?.uuid,
                    }}
                    // to={{ hash: '#author' }}
                  >
                    {authorDisplayName}
                  </NamedLink>
                </>
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              // onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
              onAddToCart={onAddToCart}
              setVariant={setVariant}
              selectedVariant={selectedVariant}
              additionalContent={additionalContent}
              isForLandingPage
            />
          </div>
          {
            authorContactEmail
            && (
              <div className={css.contact}>
                Have questions about <b>{possessive(authorDisplayName)}</b> products or policies? Contact them <a href={`mailto:${authorContactEmail}`}>here</a>.
                {/* For questions and/or product support, please contact <a href={`mailto:${authorContactEmail}`}>{authorDisplayName}</a>. */}
              </div>  
            )
          }
        </div>
        <div className={css.orderColumnForProductLayout}>
          <OrderPanel
            className={css.productOrderPanel}
            listing={currentListing}
            isOwnListing={isOwnListing}
            onSubmit={handleOrderSubmit}
            authorLink={ 
              <>
                <span style={{ paddingRight: 6 }}>Sold by</span>
                <NamedLink
                  className={css.authorNameLink}
                  name="ProfilePage"
                  params={{
                    ...params,
                    id: currentAuthor?.id?.uuid,
                  }}
                  // to={{ hash: '#author' }}
                >
                  {authorDisplayName}
                </NamedLink>
              </>
            }
            title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
            titleDesktop={
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </H4>
            }
            author={ensuredAuthor}
            onManageDisableScrolling={onManageDisableScrolling}
            // onContactUser={onContactUser}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            validListingTypes={config.listing.listingTypes}
            marketplaceCurrency={config.currency}
            dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
            marketplaceName={config.marketplaceName}
            onAddToCart={onAddToCart}
            setVariant={setVariant}
            selectedVariant={selectedVariant}
            additionalContent={additionalContent}
          />
        </div>
      </div>
    </>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  // inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  // reviews: arrayOf(propTypes.review),
  // fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  // onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  onShowCart: func.isRequired,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    // reviews,
    // fetchReviewsError,
    monthlyTimeSlots,
    // sendInquiryInProgress,
    // sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    // submitListingReviewInProgress,
    // submitListingReviewError,
    // toggleListingReviewInProgress,
    // toggleListingReviewError,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const {
    entities,
  } = state.marketplaceData;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    // reviews,
    // fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    // sendInquiryInProgress,
    // sendInquiryError,
    // submitListingReviewInProgress,
    // submitListingReviewError,
    // toggleListingReviewInProgress,
    // toggleListingReviewError,
    entities,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  // onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onAddToCart: (data) => dispatch(addToCart(data)),
  // onSubmitListingReview: (data) => dispatch(doSubmitListingReview(data)),
  // onToggleListingReview: (reviewId, listingId) => dispatch(doToggleListingReview(reviewId, listingId)),
  onShowListing: (listingId, config) => dispatch(showListing(listingId, config)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
