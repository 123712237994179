import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import ReactPlayer from 'react-player/file';
// import classNames from 'classnames';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
  H2,
  H4,
} from '../../../../components';
import { isMobileSafari } from '../../../../util/userAgent';

import css from './SectionReencleComposter.module.css';

const ReencleContentHow = props => {
  const {
    sectionId,
  } = props;

  const [videoIndex, setVideoIndex] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [isMobSafari, setIsMobSafari] = useState(false);

  useEffect(() => {
    const is_mobile_safari = isMobileSafari();
    setIsMobSafari(is_mobile_safari);
  }, []);

  const contentArray = [
    {
      img_src: '/static/reencle/video_01_Set_Up.png',
      label: 'Set up Reencle with our microbes',
      content: (
        <p>
          Unpack your Reencle, dump in our packet of microbes, and let the colony establish
        </p>
      ),
      video_src: 'https://cdn.shopify.com/videos/c/o/v/178f862af595440888e8c987ded0b126.mp4',
    },
    {
      img_src: '/static/reencle/video_02_dispose_of.png',
      label: 'Dispose up to 2.2 lbs of food scraps per day',
      content: (
        <p>
          Feed your compost by disposing of food scraps regularly
        </p>
      ),
      video_src: 'https://cdn.shopify.com/videos/c/o/v/fbb852db6fff4596b7fca4c1c929ae62.mp4',
    },
    {
      img_src: '/static/reencle/video_03_empty.png',
      label: 'Empty every 1-3 months',
      content: (
        <p>
          Spread your nutrient-dense fertilizer in your garden or on your houseplants1. Set up Reencle with our microbes
        </p>
      ),
      video_src: 'https://cdn.shopify.com/videos/c/o/v/3f1ddbc8b18041aa946e4a0527e7b518.mp4',
    },
  ];

  const showVideo = index => () => {
    setVideoIndex(index);
    setShowVideoModal(true);
  }

  const closeVideo = () => {
    setVideoIndex(null);
    setShowVideoModal(false);
  }

  return (
    <>
      <H2 className={css.tabSectionTitle}>How Does Reencle Work?</H2>
      <div id={sectionId} className={css.tabContentContainer}>
        <div className={css.layout33}>
          {
            contentArray.map((content_data, index) => {
              const {
                img_src,
                label,
                content,
                video_src,
              } = content_data || {};
              return (
                <div className={css.layout33Item}>
                  <div className={css.videoContainerMobile}>
                    <img src={img_src} className={css.responsiveImage} alt={label} onClick={showVideo(index)} style={{ cursor: 'pointer' }} />
                  </div>
                  <div className={css.videoContainerDesktop}>
                    <div className={css.videoContainer}>
                      <ReactPlayer muted url={video_src} playing={!isMobSafari} stopOnUnmount />
                    </div>
                  </div>
                  <H4 className={css.layout33Label}>{`${index + 1}. ${label}`}</H4>
                  {content}
                </div>
              );
            })
          }
        </div>
        <div className={css.howContent}>
          <p style={{ textAlign: 'center', maxWidth: 800, margin: '0 auto' }}>
            Our system closely mirrors the Aerobic Composting process but creates an optimized environment that speeds up the microbial decomposition of food scraps.
          </p>

          <p style={{ textAlign: 'center' }}>
            In fact, most food scraps can be broken down within just a day.
          </p>
        </div>
        <Dialog
          open={showVideoModal}
          onClose={closeVideo}
          aria-labelledby="video-modal-title"
          aria-describedby="video-modal-description"
        >
          <DialogTitle id="video-modal-title">{contentArray[videoIndex]?.label}</DialogTitle>
          <DialogContent>
            <div className={css.videoContainer}>
              <ReactPlayer controls url={contentArray[videoIndex]?.video_src} playing stopOnUnmount />
            </div>
          </DialogContent>
          <IconButton
            aria-label="close"
            onClick={closeVideo}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>
      </div>
    </>
  );
};

ReencleContentHow.defaultProps = {
  sectionId: null,
};

ReencleContentHow.propTypes = {
  sectionId: string,
};

export default ReencleContentHow;
