import React, { useState } from 'react';
import { bool, func, node, object, shape, string, number } from 'prop-types';
import classNames from 'classnames';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { withViewport } from '../../../../util/uiHelpers';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionTakeover.module.css';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionTakeoverComponent = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
    viewport,
  } = props;

  const [showTakeoverModal, setShowTakeoverModal] = useState(true);

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;
  let additional_classname = null;

  const closeTakeoverModal = () => {
    setShowTakeoverModal(false);
  }

  const isMobileLayout = viewport.width < 1024;

  return (
    <Dialog
      open={showTakeoverModal}
      onClose={closeTakeoverModal}
      fullWidth
      maxWidth={isMobileLayout ? 'lg' : 'md'}
      aria-labelledby="takeover-modal-title"
      aria-describedby="takeover-modal-description"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflowX: 'hidden',
        },
      }}
    >
      <DialogTitle id="takeover-modal-title"></DialogTitle>
      <DialogContent
        style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      >
        <SectionContainer
          id={sectionId}
          className={className}
          rootClassName={classNames(rootClassName || css.root, additional_classname)}
          appearance={appearance}
          options={fieldOptions}
          noPadding
        >
          {hasHeaderFields ? (
            <header className={defaultClasses.sectionDetails}>
              <Field data={title} className={defaultClasses.title} options={fieldOptions} />
              <Field data={description} className={defaultClasses.description} options={fieldOptions} />
              <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
            </header>
          ) : null}
          {hasBlocks ? (
            <div
              className={classNames(defaultClasses.blockContainer, css.featuresMain, {
                [css.noSidePaddings]: isInsideContainer,
              })}
            >
              <BlockBuilder
                rootClassName={css.block}
                ctaButtonClass={defaultClasses.ctaButton}
                blocks={blocks}
                sectionId={sectionId}
                responsiveImageSizes="(max-width: 767px) 100vw, 568px"
                options={options}
              />
            </div>
          ) : null}

        </SectionContainer>
      </DialogContent>
      <IconButton
        aria-label="close"
        onClick={closeTakeoverModal}
        sx={{
          position: 'absolute',
          right: -8,
          top: -8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionTakeoverComponent.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionTakeoverComponent.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

const SectionTakeover = withViewport(SectionTakeoverComponent);

export default SectionTakeover;
