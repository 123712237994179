import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  H2,
  H4,
} from '../../../../components';

import css from './SectionReencleComposter.module.css';

const ReencleContentEasy = props => {
  const {
    sectionId,
  } = props;

  return (
    <div className={css.easySection}>
      <H2 className={classNames(css.tabSectionTitle, css.noBottomMargin)}>Composting Made Easy</H2>
      <p style={{ textAlign: 'center' }}>Loved by <span className={css.highlightedText}>150k+ Gardeners</span></p>
      <div id={sectionId} className={css.tabContentContainer}>
        <div className={classNames(css.layout50, css.easyLayout)}>
          <div className={css.layout50Item}>
            <div className={css.easyContent}>
              <div className={css.easyImgContainer}>
                <img src="/static/reencle/easy.img.01.png" className={css.responsiveImage} alt="Composting Dehydrators" />
              </div>
              <div className={css.easyTextContent}>
                <p>'Composting' Dehydrators</p>
                <div>
                  <CancelIcon color="error" /> Not real compost
                </div>
                <div>
                  <CancelIcon color="error" /> Creates Odors
                </div>
                <div>
                  <CancelIcon color="error" /> Filters cost $220/year
                </div>
                <div>
                  <CancelIcon color="error" /> Subscription required
                </div>
              </div>
            </div>
          </div>
          <div className={css.easyVS}>
            VS
          </div>
          <div className={css.layout50Item}>
            <div className={css.easyContent}>
              <div className={css.easyImgContainer}>
                <img src="/static/reencle/easy.img.02.png" className={css.responsiveImage} alt="Composting Dehydrators" />
              </div>
              <div className={classNames(css.easyTextContent, css.easyTextContentIndented)}>
                <p>Reencle</p>
                <div>
                  <CheckCircleIcon color="success" /> Real compost
                </div>
                <div>
                  <CheckCircleIcon color="success" /> Odorless
                </div>
                <div>
                  <CheckCircleIcon color="success" /> Filters cost $35/year
                </div>
                <div>
                  <CheckCircleIcon color="success" /> No hidden fees
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ReencleContentEasy.defaultProps = {
  sectionId: null,
};

ReencleContentEasy.propTypes = {
  sectionId: string,
};

export default ReencleContentEasy;
