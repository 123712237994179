import React, { useEffect, useState, useRef } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionCarousel.module.css';

const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 290px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

// Section component that's able to show blocks in a carousel
// the number blocks visible is defined by "numColumns" prop.
const SectionCarousel = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    blocks: defaultBlocks,
    options,
  } = props;
  const sliderContainerId = `${props.sectionId}-container`;
  const sliderBtnContainerId = `${props.sectionId}-btn-container`;
  const sliderId = `${props.sectionId}-slider`;
  const isRotating = ['new-fall-bulbs'].includes(sectionId);

  const [carouselIndex, setCarouselIndex] = useState(1);
  const [doRotate, setDoRotate] = useState(isRotating);
  const blocks = isRotating ? [
    defaultBlocks[defaultBlocks.length - 1],
    ...defaultBlocks,
    defaultBlocks[0],
  ] : [ ...defaultBlocks ];

  const numberOfBlocks = blocks?.length;
  const hasBlocks = numberOfBlocks > 0;

  useEffect(() => {
    const setCarouselWidth = () => {
      var slider = window.document.getElementById(sliderId);
      if (hasBlocks) {
        const windowWidth = window.innerWidth;
        const elem = window.document.getElementById(sliderContainerId);
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        const elementWidth =
          (elem?.clientWidth || 0) >= windowWidth - scrollbarWidth ? windowWidth : (elem?.clientWidth || 0);
        const carouselWidth = elementWidth - scrollbarWidth;

        elem.style.setProperty('--carouselWidth', `${carouselWidth}px`);

        if (isRotating) {
          var slider = window.document.getElementById(sliderId);
          slider.style.setProperty('scroll-behavior', 'auto');
          const slideWidth = numColumns * slider?.firstChild?.clientWidth;
          slider.scrollLeft = slider.scrollLeft + slideWidth;
          slider.style.setProperty('scroll-behavior', 'smooth');
        }
      }
      slider.style.setProperty('visibility', 'visible');
    };
    setCarouselWidth();

    window.addEventListener('resize', setCarouselWidth);
  }, []);

  useEffect(() => {
    if (isRotating && doRotate) {
      setTimeout(() => {
        onSlideRight(null, carouselIndex);
      }, 5000);
    }
  }, [carouselIndex]);

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const jumpToSlide = index => {
   var slider = window.document.getElementById(sliderId);
    slider.style.setProperty('scroll-behavior', 'auto');
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = index * slideWidth;
    slider.style.setProperty('scroll-behavior', 'smooth');
  }

  const onSlideLeft = (e, carIndex = 0) => {
    if (carIndex === 0 && isRotating) {
      jumpToSlide(blocks.length - 2);
      setCarouselIndex(blocks.length - 3);
    } else {
      setCarouselIndex(prevIndex => prevIndex - 1);
    }
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    if (slider) {
      slider.scrollLeft = slider.scrollLeft - slideWidth;
    }
    // Fix for Safari
    if (e) {
      e.target.focus();
    }
  };

  const onSlideRight = (e, carIndex = 0) => {
    // console.log('carouselIndex', carIndex, carouselIndex, blocks.length - 1);
    if (carIndex === blocks.length - 1 && isRotating) {
      jumpToSlide(1);
      setCarouselIndex(2);
    } else {
      setCarouselIndex(prevIndex => prevIndex + 1);
    }
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    if (slider) {
      slider.scrollLeft = slider.scrollLeft + slideWidth;
    }
    // Fix for Safari
    if (e) {
      e.target.focus();
    }
  };

  const onKeyDown = (e, carIndex) => {
    if (e.keyCode === KEY_CODE_ARROW_LEFT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideLeft(e, carIndex);
    } else if (e.keyCode === KEY_CODE_ARROW_RIGHT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideRight(e, carIndex);
    }
  };

  const sectionsWithoutGrayGradient = ['shop-by-category'];
  let gradientClass = sectionsWithoutGrayGradient.includes(sectionId) ? css.gradient : css.gradientGray;
  const headerClass = sectionsWithoutGrayGradient.includes(sectionId) ? classNames(css.nonGridHeader, defaultClasses.sectionDetails) : defaultClasses.sectionDetails;
  const sectionsWithNoGradient = ['new-fall-bulbs'];
  if (sectionsWithNoGradient.includes(sectionId)) {
    gradientClass = null;
  }

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={headerClass}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {hasBlocks ? (
        <div className={css.carouselContainer} id={sliderContainerId}>
          <div
            className={classNames(css.carouselArrows, {
              [css.notEnoughBlocks]: numberOfBlocks <= numColumns,
            }, css[sliderBtnContainerId])}
          >
            <button className={css.carouselArrowPrev} onClick={(e) => { setDoRotate(false); onSlideLeft(e, carouselIndex); }} onKeyDown={(e) => {setDoRotate(false); onKeyDown(e, carouselIndex); }}>
              ‹
            </button>
            <button className={css.carouselArrowNext} onClick={(e) => { setDoRotate(false); onSlideRight(e, carouselIndex); }} onKeyDown={(e) => {setDoRotate(false); onKeyDown(e, carouselIndex); }}>
              ›
            </button>
          </div>
          <div className={getColumnCSS(numColumns)} id={sliderId} style={isRotating ? {visibility: 'hidden'} : {}}>
            <BlockBuilder
              rootClassName={css.block}
              ctaButtonClass={defaultClasses.ctaButton}
              blocks={blocks}
              sectionId={sectionId}
              responsiveImageSizes={getResponsiveImageSizes(numColumns)}
              options={options}
            />
          </div>
          <div className={gradientClass} />
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCarousel.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  options: null,
};

SectionCarousel.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionCarousel;
