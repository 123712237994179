import React, { useState } from 'react';
import { string } from 'prop-types';
// import classNames from 'classnames';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  H2,
  H4,
} from '../../../../components';

import css from './SectionReencleComposter.module.css';

const ReencleContentWhat = props => {
  const {
    sectionId,
  } = props;

  const [selectedFAQIndex, setSelectedFAQIndex] = useState(0);

  const imageArray = [
    {
      src: '/static/reencle/what_Icon_01_Fruit.png',
      label: 'Fruit',
    },
    {
      src: '/static/reencle/what_Icon_02_Vegetables.png',
      label: 'Vegetables',
    },
    {
      src: '/static/reencle/what_Icon_03_Fish.png',
      label: 'Meat, Fish',
    },
    {
      src: '/static/reencle/what_Icon_04_Noodles.png',
      label: 'Noodles, Grain',
    },
    {
      src: '/static/reencle/what_Icon_05_Bread.png',
      label: 'Bread, Cookies',
    },
    {
      src: '/static/reencle/what_Icon_06_Pizza.png',
      label: 'Pizza Crusts',
    },
    {
      src: '/static/reencle/what_Icon_07_Sausage.png',
      label: 'Sausage, Salami',
    },
    {
      src: '/static/reencle/what_Icon_08_Cheese.png',
      label: 'Cheese',
    },
    {
      src: '/static/reencle/what_Icon_09_Watermelon.png',
      label: 'Watermelon Rinds',
    },
    {
      src: '/static/reencle/what_Icon_10_cooked_eggs.png',
      label: 'Cooked Eggs',
    },
  ];

  const faqArray = [
    {
      title: 'Meats and Dairy',
      content: (
        <>
          <p className={css.noTopMargin}>Toss your meat in!</p>
          <p>
            Reencle is designed to handle a variety of organic waste, including meat and dairy products, without any issues. Feel free to compost your leftover meats and old cheese with confidence. Our advanced microbial system is highly effective in breaking down and digesting these materials, ensuring a seamless composting process.
          </p>
        </>
      )
    },
    {
      title: 'Fruits and Vegetables',
      content: (
        <>
          <p className={css.noTopMargin}>Have fruit peels and a few overripe tomatoes on hand? No problem!</p>
          <p>
          With confidence, compost your fruit and veggie scraps effortlessly. Revel in the simplicity of composting a diverse range of kitchen leftovers. Just a heads-up, though: be sure to avoid hard pits and seeds in the compost mix.
          </p>
        </>
      )
    },
    {
      title: 'Egg shells and Coffee Grounds',
      content: (
        <>
          <p className={css.noTopMargin}>Egg shells after breakfast or a delightful baking session? Don't discard them—simply toss them into Reencle!</p>
          <p>
          In fact, eggshells are an excellent source of calcium for your compost. Additionally, if you have coffee grounds, adding a scoop a day not only contributes to your compost but also helps control any unwanted odors. Make the most of your kitchen waste with Reencle
          </p>
        </>
      )
    },
  ];

  return (
    <>
      <H2 className={css.tabSectionTitle}>Process These Foods &amp; More</H2>
      <div id={sectionId} className={css.tabContentContainer}>
        <div className={css.layout10}>
          {
            imageArray.map((img_data) => {
              return (
                <div className={css.layout10Item}>
                  <img src={img_data.src} className={css.responsiveImage} alt={img_data.label} />
                  <H4 className={css.layout10Label}>{img_data.label}</H4>
                </div>
              );
            })
          }
        </div>
        <div className={css.layout50} style={{ marginTop: 30 }}>
          <div className={css.layout50Item}>
            <img src="/static/reencle/wcic_image.jpg" className={css.responsiveImage} alt="What can I compost?" />
          </div>
          <div className={css.layout50Item}>
            {
              faqArray.map((faq_data, index) => {
                const {
                  title,
                  content,
                } = faq_data;
                return (
                  <Accordion
                    elevation={0}
                    className={css.accordion}
                    expanded={index === selectedFAQIndex}
                    onChange={() => setSelectedFAQIndex(index)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={css.accordionSummary}
                      classes={{
                        content: css.accordionSummaryContent,
                      }}
                    >
                      <b>{title}</b>
                    </AccordionSummary>
                    <AccordionDetails
                      className={css.accordionDetails}
                    >
                      {content}
                    </AccordionDetails>
                  </Accordion>
                );
            })
          }     
          </div>
        </div>
      </div>
    </>
  );
};

ReencleContentWhat.defaultProps = {
  sectionId: null,
};

ReencleContentWhat.propTypes = {
  sectionId: string,
};

export default ReencleContentWhat;
