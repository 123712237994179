import React from 'react';
import { arrayOf, bool, func, node, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';

// Section components
import SectionArticle from './SectionArticle';
import SectionCarousel from './SectionCarousel';
import SectionColumns from './SectionColumns';
import SectionFeatures from './SectionFeatures';
import SectionHero from './SectionHero';
import SectionHeroSearch from './SectionHeroSearch';
import SectionListing from './SectionListing';
import SectionListings from './SectionListings';
import SectionCuratedListings from './SectionCuratedListings';
import SectionLatestListings from './SectionLatestListings';
import SectionFAQ from './SectionFAQ';
import SectionReencleComposter from './SectionReencleComposter';
import SectionComboListing from './SectionComboListing';
import SectionNav from './SectionNav';
import SectionTakeover from './SectionTakeover';

// Styles
// Note: these contain
// - shared classes that are passed as defaultClasses
// - dark theme overrides
// TODO: alternatively, we could consider more in-place way of theming components
import css from './SectionBuilder.module.css';
import SectionFooter from './SectionFooter';

// These are shared classes.
// Use these to have consistent styles between different section components
// E.g. share the same title styles
const DEFAULT_CLASSES = {
  sectionDetails: css.sectionDetails,
  title: css.title,
  description: css.description,
  ctaButton: css.ctaButton,
  blockContainer: css.blockContainer,
};

/////////////////////////////////////////////
// Mapping of section types and components //
/////////////////////////////////////////////

const defaultSectionComponents = {
  article: { component: SectionArticle },
  carousel: { component: SectionCarousel },
  columns: { component: SectionColumns },
  features: { component: SectionFeatures },
  footer: { component: SectionFooter },
  hero: { component: SectionHero },
};

//////////////////////
// Section builder //
//////////////////////

const SectionBuilder = props => {
  const {
    sections,
    options,
    onSearchSubmit = () => {},
    featured_listings = [],
    latest_listings = [],
    promoted_listings = [],
    curated_carousel_listings = [],
    curated_stack_listings = [],
    specialty_listings = [],
    pollinator_listings = [],
    small_space_listings = [],
    unique_listings = [],
    on_sale_listings = [],
    sideNavTab,
  } = props;
  const { sectionComponents = {}, isInsideContainer, ...otherOption } = options || {};

  // If there's no sections, we can't render the correct section component
  if (!sections || sections.length === 0) {
    return null;
  }

  // Selection of Section components
  const components = { ...defaultSectionComponents, ...sectionComponents };
  const getComponent = sectionType => {
    const config = components[sectionType];
    return config?.component;
  };

  // getListingsAccordingToSectionId
  const getListingsAccordingToSectionId = (listings_list, sectionId) => {
    const listings = (listings_list || []).filter((listing) => {
      const curr_specialties = listing?.attributes?.publicData?.specialty
        || listing?.attributes?.publicData?.pub_specialty || [];
      if (Array.isArray(curr_specialties)) {
        return curr_specialties.includes(sectionId);
      }
      return false;
    });
    return listings;
  };

  // Generate unique ids for sections if operator has managed to create duplicates
  // E.g. "foobar", "foobar1", and "foobar2"
  const sectionIds = [];
  const getUniqueSectionId = (sectionId, index) => {
    const candidate = sectionId || `section-${index + 1}`;
    if (sectionIds.includes(candidate)) {
      let sequentialCandidate = `${candidate}1`;
      for (let i = 2; sectionIds.includes(sequentialCandidate); i++) {
        sequentialCandidate = `${candidate}${i}`;
      }
      return getUniqueSectionId(sequentialCandidate, index);
    } else {
      sectionIds.push(candidate);
      return candidate;
    }
  };

  return (
    <>
      {sections.map((section, index) => {
        // If the default "dark" theme should be applied (when text color is white).
        // By default, this information is stored to customAppearance field
        const isDarkTheme =
          section?.appearance?.fieldType === 'customAppearance' &&
          section?.appearance?.textColor === 'white';
        const classes = classNames({ [css.darkTheme]: isDarkTheme });
        const sectionId = getUniqueSectionId(section.sectionId, index);
        if (sectionId && sectionId.indexOf('-disabled') >= 0) {
          return null;
        }
        if (sectionId && sectionId.indexOf('-takeover') >= 0) {
          return <SectionTakeover
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            options={otherOption}
            {...section}
            sectionId={sectionId}
          />
        }
        if (sectionId === 'hero-search') {
          return <SectionHeroSearch
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
            onSearchSubmit={onSearchSubmit}
          />
        }
        if (sectionId === 'featured-listing') {
          return <SectionListing
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
          />
        }
        if (sectionId === 'featured-listings') {
          return <SectionListings
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
            listings={featured_listings}
            viewAllCategory="seasonal-garden-essentials"
          />
        }
        if (sectionId === 'latest-listings') {
          return <SectionLatestListings
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
            listings={latest_listings}
          />
        }
        if (sectionId === 'promoted-listings') {
          return <SectionListings
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
            listings={promoted_listings}
            numCols={2}
          />
        }
        if (sectionId === 'reencle-composter') {
          return <SectionReencleComposter
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
          />
        }
        const combo_listing_prefixes = [
          'gg24-combo',
        ];
        if (combo_listing_prefixes.some(prefix => sectionId.indexOf(prefix) >= 0)) {
          return <SectionComboListing
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
          />
        }
        if (sectionId === 'gg24-nav') {
          return <SectionNav
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
          />
        }
        const listings_ids = [
          'pollinator-listings',
          'small-space-listings',
          'unique-listings',
          'on-sale-listings',
          'small-business-gifts-listings',
          'winter-plants-listings',
          'indoor-gardening-listings',
        ];
        if (listings_ids.includes(sectionId)) {
          let target_listings = null;
          let viewAllCategory = null;
          switch (sectionId) {
            case 'small-business-gifts-listings':
              target_listings = getListingsAccordingToSectionId(specialty_listings, 'small-business-gifts');
              viewAllCategory = sectionId;
              break
            case 'winter-plants-listings':
              target_listings = getListingsAccordingToSectionId(specialty_listings, 'winter-plants-seasonal-interest');
              viewAllCategory = sectionId;
              break
            case 'indoor-gardening-listings':
              target_listings = getListingsAccordingToSectionId(specialty_listings, 'indoor-gardening-essentials');
              viewAllCategory = sectionId;
              break
            case 'pollinator-listings':
              target_listings = pollinator_listings;
              viewAllCategory = 'pollinator-garden';
              break;
            case 'small-space-listings':
              target_listings = small_space_listings;
              viewAllCategory = 'small-space';
              break;
            case 'unique-listings':
              target_listings = unique_listings;
              viewAllCategory = 'unique';
              break;
            case 'on-sale-listings':
              target_listings = on_sale_listings;
              viewAllCategory = 'on-sale';
              break;
            default:
              target_listings = [];
              viewAllCategory = sectionId;
              break;
          }
          return <SectionListings
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
            listings={target_listings}
            viewAllCategory={viewAllCategory}
            isSquare={false}
          />
        }
        if (sectionId === 'curated-listings') {
          return <SectionCuratedListings
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
            carouselListings={curated_carousel_listings}
            stackListings={curated_stack_listings}
            numCols={4}
          />
        }
        if (`${sectionId}`.indexOf('faq-') >= 0) {
          if (sideNavTab && sectionId !== sideNavTab) {
            return null;
          }
          return <SectionFAQ
            key={`${sectionId}_i${index}`}
            className={classes}
            defaultClasses={DEFAULT_CLASSES}
            isInsideContainer={isInsideContainer}
            options={otherOption}
            {...section}
            sectionId={sectionId}
            numCols={2}
          />
        }
        const Section = getComponent(section.sectionType);
        

        if (Section) {
          return (
            <Section
              key={`${sectionId}_i${index}`}
              className={classes}
              defaultClasses={DEFAULT_CLASSES}
              isInsideContainer={isInsideContainer}
              options={otherOption}
              {...section}
              sectionId={sectionId}
            />
          );
        } else {
          // If the section type is unknown, the app can't know what to render
          console.warn(
            `Unknown section type (${section.sectionType}) detected using sectionName (${section.sectionName}).`
          );
          return null;
        }
      })}
    </>
  );
};

const propTypeSection = shape({
  sectionId: string,
  sectionName: string,
  sectionType: oneOf(['article', 'carousel', 'columns', 'features', 'hero']).isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
  sectionComponents: shape({ component: node }),
  // isInsideContainer boolean means that the section is not taking
  // the full viewport width but is run inside some wrapper.
  isInsideContainer: bool,
});

const defaultSections = shape({
  sections: arrayOf(propTypeSection),
  options: propTypeOption,
});

const customSection = shape({
  sectionId: string.isRequired,
  sectionType: string.isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});
const propTypeOptionForCustomSections = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
  sectionComponents: shape({ component: node }).isRequired,
  // isInsideContainer boolean means that the section is not taking
  // the full viewport width but is run inside some wrapper.
  isInsideContainer: bool,
});

const customSections = shape({
  sections: arrayOf(customSection),
  options: propTypeOptionForCustomSections.isRequired,
});

SectionBuilder.defaultProps = {
  sections: [],
  options: null,
  sideNavTab: null,
};

SectionBuilder.propTypes = oneOf([defaultSections, customSections]).isRequired;

export default SectionBuilder;
