import pick from 'lodash/pick';

import { types as sdkTypes, createImageVariantConfig } from '../../../../util/sdkLoader';
import { storableError } from '../../../../util/errors';
import { addMarketplaceEntities } from '../../../../ducks/marketplaceData.duck';
import {
  getComboListings,
} from '../../../../util/api';
/*
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { fetchCart } from '../../containers/CartListingPage/CartListingPage.duck';

const { UUID } = sdkTypes;
*/

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/SectionComboListing/SET_INITIAL_VALUES';

export const FETCH_LISTINGS_REQUEST = 'app/SectionComboListing/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/SectionComboListing/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/SectionComboListing/FETCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchListingsError: null,
  listings: {},
};

const sectionComboListingReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_LISTINGS_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case FETCH_LISTINGS_SUCCESS:
      const new_listings = { ...state.listings };
      new_listings[payload.groupId] = payload.data;
      return { ...state, listings: new_listings, showListingError: null };
    case FETCH_LISTINGS_ERROR:
      return { ...state, showListingError: payload };

    default:
      return state;
  }
};

export default sectionComboListingReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchListingsRequest = groupId => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { groupId },
});

export const fetchListingsSuccess = (groupId, data) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { groupId, data },
});

export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchComboListingData = (groupId, config, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest(groupId));
  console.log('fetchComboListingData', groupId);
  return getComboListings(groupId)
    .then(data => {
      // const listingFields = config?.listing?.listingFields;
      // const sanitizeConfig = { listingFields };
      // dispatch(addMarketplaceEntities(data, sanitizeConfig));
      dispatch(fetchListingsSuccess(groupId, data));
      return data;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
    });
};
